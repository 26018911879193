import React, { FC, useEffect, useRef } from 'react';
import { TemplateSrv, locationService, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import L, { LatLng } from 'leaflet';
import 'leaflet-kmz';
import './css/leaflet.css';
import { icons } from './Icons';
import {valueToSubtype } from './types';

interface MapComponentProps {
  items: Array<DynamicTableItemProps<T>>;
  currentNodes: [];
  onCoordinatesChange: (coords: [number, number], currentPlace: string) => void;
  onImportData: (newElements: []) => void;
  onSelectionChange: (uid: string) => void;
  markers: [];
  showMarkerIcons: boolean;
  mapSource: string;
  geoVariables: [boolean, string, string, string, string];
  readOnly: boolean;
  tableMode: boolean;
  isKmzAddMode: any;
}

export const MapComponent: FC<MapComponentProps> = ({ 
  items,
  currentNodes,
  onCoordinatesChange,
  onImportData,
  onSelectionChange,
  markers,
  showMarkerIcons,
  mapSource,
  geoVariables,
  readOnly,
  tableMode,
  isKmzAddMode
}) => {
  console.log(currentNodes, markers, items, geoVariables);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const leafRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);
  const kmzRef = useRef<L.kmzLayer | null>(null);
  var leaf: any = leafRef.current;
  var marker: any = markerRef.current;
  var kmz: any = kmzRef.current;
  var control: any;
  const base = 'https://services.arcgisonline.com/ArcGIS/rest/services/';
  const arcgisUrl = `${base}${mapSource}/MapServer/tile/{z}/{y}/{x}`;
  const attribution = `Tiles © <a href="${base}${mapSource}/MapServer">ArcGIS</a>`;
  const initialZ = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[1]}`)) : 15;
  const xPos = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[2]}`)) : 0;
  const yPos = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[3]}`)) : 0;

  var currentPathElements: [] = [];
  var aditionalPlaces = markers;
  var aditionalEmps = items.filter(value => value.data.elementType === 'emp');
  let coordsX = 0;
  let coordsY = 0;
  let hasCurrentNode = false;
  if (currentNodes[0] && currentNodes[0] !== null && currentNodes[0].uid !== null) {
    currentPathElements = currentNodes[0].pathId !== null && currentNodes[0].pathId !== '' ? 
      markers.filter(value => value.pathId === currentNodes[0].pathId) : markers;
    aditionalPlaces = currentNodes[0].uid !== null && currentNodes[0].uid !== '' ? 
      currentPathElements.filter(value => value.pathId !== currentNodes[0].uid) : currentPathElements;
    coordsX = geoVariables[0] && currentNodes[0].lat ? currentNodes[0].lat : 0;
    coordsY = geoVariables[1] && currentNodes[0].lng ? currentNodes[0].lng : 0;
	hasCurrentNode = true;
  }
  console.log(aditionalPlaces, aditionalEmps);
  const initialX = geoVariables[0] && coordsX === 0 ? xPos : coordsX;
  const initialY = geoVariables[0] && coordsY === 0 ? yPos : coordsY;

  const familyToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const mapOptions = {
	center: { lat: initialX, lng: initialY },
	zoom: initialZ,
	minZoom: 3,
	maxZoom: 19,
	zoomControl: false,
	boxZoom: true,
	scrollWheelZoom: true,
	touchZoom: true,
  };

  const toLatLng = (lat, lng) => [parseFloat(lat), parseFloat(lng)];

  const addMarkers = (places: [], map: L.Map) => {
    const markerLines = new Map();
	const allLines = [];
	let originalPosition = null;
    places.forEach(place => {
      if (place) {
        const iconType = place.family;
        const family = familyToString(place.family);
        const icon = icons[iconType] || icons['defaultIcon'];
        if (!isNaN(place.lat) && !isNaN(place.lng)) {
          const currentPlace = hasCurrentNode  ? currentNodes.find(ele => ele.uid === place.elementId) : undefined;
		  const isItemFiltered = items ? items.find(ele => ele.data.uid === place.elementId) : undefined;
		  const opacityValue = isItemFiltered ? 1 : 0.25;
		  const dragable = isItemFiltered || readOnly ? false : !readOnly;
          if (showMarkerIcons || iconType === 'emp') {
            const marker = currentPlace ? 
			  L.marker([place.lat, place.lng], { icon: icons['current'], draggable: true })
                .addTo(map)
                .bindPopup(`<b>${family}</b><br>${place.label}<br>${place.description}`) 
			:
			  L.marker([place.lat, place.lng], { icon: icon, opacity: opacityValue, draggable: dragable })
                .addTo(map)
                .bindPopup(`<b>${family}</b><br>${place.label}<br>${place.description}`);

            marker.id = place.elementId;
			const parentLatLng = place.destLat !== null && place.destLng !== null && place.destLat !== place.lat && place.destLng !== place.lng
              ? toLatLng(place.destLat, place.destLng)
              : null;

            if (parentLatLng !== null) {
              const elementLatLng = toLatLng(place.lat, place.lng);
              const line = L.polyline([elementLatLng, parentLatLng], { color: 'brown', weight: 2, opacity: opacityValue, dashArray: '5, 5' }).addTo(map);
              markerLines.set(marker, line);
			  allLines.push(line); 
            }
			marker.on('movestart', (event) => {
              originalPosition = event.target.getLatLng();
            });
			if (iconType !== 'emp') {
			  marker.on('moveend', (event) => {
			    let mustUpdate = false;
				const newLatLng = event.target.getLatLng();
                console.log(marker);
				const line = markerLines.get(marker);
				allLines.forEach(existingLine => {
				  const latLngs = existingLine.getLatLngs();
				  const isNotIconLine = line ? line._leaflet_id !== existingLine._leaflet_id : false;
				  if (isNotIconLine || !line) {
					if (latLngs[0] !== null) {
					  if (latLngs[0].lat === originalPosition.lat && latLngs[0].lng === originalPosition.lng) {
						latLngs[0] = newLatLng;
						mustUpdate = true;
					  }
					}
					if (!mustUpdate && latLngs[1] !== null) {
					  if (latLngs[1].lat === originalPosition.lat && latLngs[1].lng === originalPosition.lng) {
						latLngs[1] = newLatLng;
						mustUpdate = true;
					  }
					}
				  } else if (line) {
					const oldLatLngs = line.getLatLngs();
					line.setLatLngs([newLatLng, oldLatLngs[1]]);
				  }
				  if (mustUpdate) {
					existingLine.setLatLngs(latLngs);
					mustUpdate = false;
				  }
				});
				if (!readOnly) {
				  onCoordinatesChange([newLatLng.lat.toFixed(7), newLatLng.lng.toFixed(7)], marker.id);
				}
              });
			  marker.on('click', (event) => {
				if (geoVariables[4] && geoVariables[4] !== '') {
				  const queryMap = {
					[`var-${geoVariables[4]}`]: marker.id,
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
					console.error(error);
				  }
				}
				if (!tableMode) {
				  onSelectionChange(marker.id);
				}
              });
			}

			if (currentPlace) {
			  marker.on('moveend', function (event) {
				const newCoords = event.target.getLatLng();
				if (!readOnly) {
				  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], marker.id);
				}
			  });
			}
          }
        } else {
          console.error(`Invalid coordinates for place: ${place.label}`);
        }
      }
    });
  };

  const drawPlaces = (places: [], map: L.Map) => {
	let originalPosition = null;
    places.forEach(place => {
      if (place) {
        const iconType = place.data.elementType;
        const family = familyToString(iconType);
        const icon = icons[iconType] || icons['defaultIcon'];
        if (!isNaN(place.data.coordinates[0]) && !isNaN(place.data.coordinates[1])) {
		  const opacityValue = 0.75;
		  const dragable = false;
          const marker = L.marker([place.data.coordinates[0], place.data.coordinates[1]], { icon: icon, opacity: opacityValue, draggable: dragable })
            .addTo(map)
            .bindPopup(`<b>${family}</b><br>${place.data.title}`);

          marker.id = place.data.uid;
		  marker.on('click', (event) => {
			if (geoVariables[4] && geoVariables[4] !== '') {
			  const queryMap = {
				[`var-${geoVariables[4]}`]: marker.data.idx,
			  };
			  try {
				locationService.partial(queryMap, true);
			  } catch (error) {
				console.error(error);
			  }
			}
			if (!tableMode) {
			  onSelectionChange(marker.id);
			}
		  });
        } else {
          console.error(`Invalid coordinates for place: ${place.label}`);
        }
      }
    });
  };

  useEffect(() => {
	if (!leaf) {
	  leaf = L.map(mapRef.current!, mapOptions);
      L.tileLayer(arcgisUrl, {
		attribution: attribution,
		minZoom: 3,
		maxZoom: 19,
	  }).addTo(leaf);

	  /*leaf.on('zoomend', (e) => {
		const zoom = leaf.getZoom();
		if (geoVariables[0] && initialZ !== zoom) {
		  const queryMap = {
			[`var-${geoVariables[1]}`]: zoom.toFixed(1),
		  };
		  try {
			locationService.partial(queryMap, true);
		  } catch (error) {
			console.log(error);
		  }
	    }
	  });*/

	  leaf.on('click', (e) => {
		console.log(e);
		if (!tableMode) {
		  onSelectionChange(null);
		}
	  });

	  console.log(isKmzAddMode, leaf);
	  if (isKmzAddMode) {
	    isKmzAddMode.onload = (event) => {
		  const base64KMZData = isKmzAddMode.result;
		  const byteCharacters = atob(base64KMZData.split(',')[1]);
		  const byteNumbers = new Array(byteCharacters.length);
		  for (let i = 0; i < byteCharacters.length; i++) {
		    byteNumbers[i] = byteCharacters.charCodeAt(i);
		  }
		  const byteArray = new Uint8Array(byteNumbers);
		  const blob = new Blob([byteArray], { type: 'application/vnd.google-earth.kmz' });
		  const blobUrl = URL.createObjectURL(blob);

	      kmz = L.kmzLayer().addTo(leaf);
	      kmz.on('load', function (e) {
	        control.addOverlay(e.layer, e.name);
	        e.layer.addTo(leaf);
			const layerGroup = e.layer;
			const geoJSONData = layerGroup.toGeoJSON();
			const elementArray = geoJSONData.features;
			onImportData(elementArray);
	      });
		  kmz.load(blobUrl);
		  kmz.on('addlayer', function (e) {
			const layerGroup = e.layer;
			const geoJSONData = layerGroup.toGeoJSON();
			console.log("Datos en formato GeoJSON:", geoJSONData);
	      });
		}
	  }

	  if (aditionalPlaces.length > 0) {
	    addMarkers(aditionalPlaces, leaf);
	  }

	  if (aditionalEmps.length > 0) {
	    drawPlaces(aditionalEmps, leaf);
	  }

	  if (!showMarkerIcons && hasCurrentNode) {
	    let nodesCount = 0;
	    currentNodes.forEach(current => {
		  if (current.uid !== null) {
		    const family = familyToString(current.family);
		    marker = L.marker(toLatLng(current.lat, current.lng), { icon: icons['current'], draggable: !readOnly })
		      .addTo(leaf)
		      .bindPopup(`<b>${family}</b><br>${current.label}`);
			nodesCount = nodesCount + 1;
		  }
		});

	    if (nodesCount > 0) {
		  marker.on('moveend', function (e) {
            const newCoords = e.target.getLatLng();
		    if (!readOnly) {
			  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], null);
			}
          });
		}
	  }
	  if (hasCurrentNode) {
	    console.log(currentNodes);
		leaf.fitBounds(leaf.getBounds());
		//leaf.flyTo([initialX, initialY], initialZ);
	  }
	  control = L.control.layers(null, null, { collapsed:false }).addTo(leaf);
    }

    return () => {
	  leaf?.remove();
    };
  }, [currentNodes, onCoordinatesChange, onImportData, onSelectionChange, mapSource, aditionalPlaces, geoVariables, isKmzAddMode]);

  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
  );
};
