import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect  } from 'react'; 
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Label,
  Form,
  HorizontalGroup,
  VerticalGroup,
  Input,
  InputControl,
  Select,
  MultiSelect,
  Switch,
  TextArea,
  Collapse,
  useStyles2,
} from '@grafana/ui';
import { CollapsibleSection } from './CollapsibleSection';
import { L1OyMData } from './L1OyMManager';
import { L1OyMExpandedForm } from './L1OyMExpandedForm';
import { MapComponent } from './PointPickerModal';
import { saveImportData, updateImportElements } from './L1OyMTable';
import { valueToType, valueToSubtype, valueToStatus, fiberOpticColors, bufferColors, multiparColors } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface L1OyMMapFormProps {
  items: Array<DynamicTableItemProps<T>>;
  onCancel: () => void;
  onImportData: (newElements: []) => void;
  onSave: (data: L1OyMData[]) => void;
  onSelect: (uid: string) => void;
  element: L1OyMData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const L1OyMMapForm: FC<L1OyMMapFormProps> = ({ 
  items,
  onCancel,
  onImportData,
  onSave,
  onSelect,
  element, 
  isDark, 
  isAdmin,
  width,
  height,
  elements,
  places,
  types,
  pluginVariables,
  isKmzAddMode
}) => {
  const styles = useStyles2(getStyles(isDark, width, height));
  const [isOpen, setIsOpen] = useState(false);
  const [relations, setRelations] = useState([]);
  const [validSubTypes, setValidSubTypes] = useState([]);
  const [linkIDs, setLinkIDs] = useState([]);
  const [sublinks, setSublinks] = useState([]);
  const [parentSubType, setParentSubType] = useState('path');
  const [segmentSubLinks, setSegmentSubLinks] = useState([]);
  const [linkCapacity, setLinkCapacity] = useState(element ? element.capacity : 0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [coordinates, setCoordinates] = useState(element ? element.coordinates : []);
  const [parentPlaces, setParentPlaces] = useState([]);
  const [pathEventRelations, setPathEventRelations] = useState([]);
  const [parentMarkers, setParentMarkers] = useState([]);

  const [isPathOpen, setIsPathOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isParentOpen, setIsParentOpen] = useState(false);
 
  console.log(element, elements, places);
  const handlePlaceCoordsChange = (coords, elementId) => {
	if (elementId && elementId !== null) {
	  const element = elements.find(ele => ele.uid === elementId);
	  console.log(element);
      if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
	    element.coordinates[0] = parseFloat(coords[0]);
	    element.coordinates[1] = parseFloat(coords[1]);
		element.wasChange = true;
	  }
	}
	setCoordinates(coords);
  };

  const handleCoordsChange = (e) => {
	const value = e.split(',').map(Number);
    if (value.length === 2 && !isNaN(value[0]) && !isNaN(value[1])) {
      setCoordinates(value);
	  return true;
    }
  };
  
  const handleAddSublink = () => {
    setSublinks([...sublinks, { sublinkId: '', buffer: '', color: '', distance: 0, attenuation: 0 }]);
  };

  const handleRemoveSublink = (index) => {
    setSublinks(sublinks.filter((_, i) => i !== index));
  };

  const handleChangeSublink = (index, field, value) => {
    const newSublinks = [...sublinks];
    newSublinks[index][field] = value;
    setSublinks(newSublinks);
  };

  const handleAddParent = () => {
    setRelations([...relations, { element_id: '', distance: '', capacity: '' }]);
  };

  const handleRemoveParent = (index) => {
    setRelations(relations.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newParents = [...relations];
    newParents[index][field] = value;
    setRelations(newParents);
	if (newParents[index].element_id !== '') {
	  updateParentMarkers(newParents);
	}
  };

  const handleSubmit = (value: any) => {
    let valuesToUpdate = value;
	valuesToUpdate.status = value.state === false ? 'disable' : value.status;
	if (value.elementType === 'path') {
	  const elementsChanged = elements.filter(ele => ele.wasChange === true);
	  elementsChanged.forEach(element => {
		const valueToUpdate = element;
		onSave(valueToUpdate, 'coordinates');
		element.wasChange = false;
	  });
	  valuesToUpdate.pathId = value.idx;
	  onSave(valuesToUpdate, 'full');
	} else if (value.elementType === 'link') {
	  valuesToUpdate.linkId = value.idx;
	  onSave(valuesToUpdate, 'full');
	} else {
	  valuesToUpdate = formatToSubmit(value);
	  onSave(valuesToUpdate, 'full');
	}
  };

  const formatToSubmit = (value: any) => {
    let valueFormated = value;
	if (value.elementType === 'element') {
	    valueFormated.elementId = value.idx;
	    valueFormated.coordinates = coordinates;
	    const relationIds = relations.map(p => p.element_id);
        const distances = relations.map(p => parseFloat(p.distance) || 0);
        const capacities = relations.map(p => parseFloat(p.capacity) || 0);
	    valueFormated.relationIds = relationIds;
	    valueFormated.relationDistances = distances;
	    valueFormated.relationCapacities = capacities;
	  } else if (value.elementType === 'segment') {
	    if (parentSubType === 'fo_path' || parentSubType === 'ge_path') {
	      valueFormated.capacity = linkCapacity;
	      valueFormated.segmentId = value.idx;
	    }
	    const sublink_ids = sublinks.map(p => p.sublinkId);
        const sublink_buffer = sublinks.map(p => p.buffer || '');
        const sublink_color = sublinks.map(p => p.color || '');
        const sublink_distances = sublinks.map(p => parseFloat(p.distance) || 0);
        const sublink_attenuation = sublinks.map(p => parseFloat(p.attenuation) || 0);
	    valueFormated.sublinkIds = sublink_ids;
	    valueFormated.sublinkBuffer = sublink_buffer;
	    valueFormated.sublinkColor = sublink_color;
	    valueFormated.sublinkDistance = sublink_distances;
	    valueFormated.sublinkAttenuation = sublink_attenuation;
	  } else {
	    valueFormated.elementId = value.idx;
	  }
	return valueFormated;
  }

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const updateValidSubTypes = (typeSelected: string) => {
	const validTypes = valueToSubtype.filter(value => value.family === typeSelected)
	setValidSubTypes(validTypes);
  }

  const subtypeToString = (value: string) => {
	const valueSelected = validSubTypes.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const placeToString = (value: string) => {
	const valueSelected = places.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const pathIDs: [] = [];
  const idList = elements.filter(value => value.elementType === 'path' && value.version > 0);

  for (let i = 0; i < idList.length; i++) {
	let newId = {
	  label: idList[i].title,
	  description: idList[i].title,
	  value: idList[i].pathId,
	};
	pathIDs.push(newId);
  }

  const updateLinks = (subType: string) => {
	const newLinksIds: [] = [];
	let linkList = elements.filter(value => value.elementType === 'link' && (value.subType === 'fo_path' || value.subType === 'ge_path'));
	if (subType === 'radio') {
      linkList = elements.filter(value => value.elementType === 'link' && (value.subType === 're_path' || value.subType === 'sa_path'));
	}

    for (let i = 0; i < linkList.length; i++) {
	  let newLink = {
	    label: linkList[i].title,
	    description: linkList[i].title,
	    value: linkList[i].linkId,
	  };
	  newLinksIds.push(newLink);
    }
	setLinkIDs(newLinksIds);
  }

  const linkToString = (value: string) => {
	const valueSelected = linkIDs.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const pathEvents: [] = [];
  const events = elements.filter(value => value.elementType === 'element');

  for (let i = 0; i < events.length; i++) {
	let pathEvent = {
	  label: events[i].title,
	  description: events[i].address,
	  value: events[i].elementId,
	  family: events[i].subType,
	  lat: events[i].coordinates[0] || 0,
	  lng: events[i].coordinates[1] || 0,
	};
	pathEvents.push(pathEvent);
  }
 
  const eventToString = (value: string) => {
	const valueSelected = pathEvents.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const linkSegments: [] = [];
  const segments = elements.filter(value => value.elementType === 'segment');

  for (let i = 0; i < segments.length; i++) {
	let linkSegment = {
	  label: segments[i].title,
	  description: segments[i].title,
	  value: segments[i].elementId,
	};
	linkSegments.push(linkSegment);
  }

  const segmentToString = (value: string) => {
	const valueSelected = linkSegments.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const coordinatesToPlace = (coordinates: []) => {
	const valueSelected = places.find(ele => 
	  parseFloat(ele.lat).toFixed(6) === parseFloat(coordinates[0]).toFixed(6) && 
	  parseFloat(ele.lng).toFixed(6) === parseFloat(coordinates[1]).toFixed(6));
	if (valueSelected) {
	  return valueSelected.value;
	} else {
	 return null;
	}
  }

  useEffect(() => {
	if (element) {
	  updateValidSubTypes(element.elementType);
      if (element.elementType === 'element') {
	    const initialParents = element.relationIds.map((relationId, index) => ({
          element_id: relationId || '', 
          distance: element.relationDistances[index] || '', 
          capacity: element.relationCapacities[index] || '',
        }));
        setRelations(initialParents);
	  } else if (element.elementType === 'segment') {
        const initialSubLinks = element.sublinkIds.map((sublinkId, index) => ({
          sublinkId: sublinkId || '', 
          buffer: element.sublinkBuffer[index] || '', 
          color: element.sublinkColor[index] || '',
          distance: element.sublinkDistance[index] || '', 
          attenuation: element.sublinkAttenuation[index] || '',
        }));
        setSublinks(initialSubLinks);
	  }
	}
  }, [element]);

  const updateElement = (subType: string, parentUid: string, pathId: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	const parentEmps: [] = [];
	if (parent) {
	  if (parent.origin !== '' || parent.destination !== '') {
		const originPlace = places.find(ele => ele.value === parent.origin);
		const newOriginPlace = {
		  elementId: originPlace.value,
		  pathId: pathId,
		  label: originPlace.label,
		  description: originPlace.description,
		  family: 'emp',
		  value: originPlace.value,
		  lat: parseFloat(originPlace.lat),
		  lng: parseFloat(originPlace.lng),
		  destLat: parseFloat(originPlace.lat),
		  destLng: parseFloat(originPlace.lng),
		};
		parentEmps.push(newOriginPlace);
		const destinationPlace = places.find(ele => ele.value === parent.destination);
		const newDestinationPlace = {
		  elementId: destinationPlace.value,
		  pathId: pathId,
		  label: destinationPlace.label,
		  description: destinationPlace.description,
		  family: 'emp',
		  value: destinationPlace.value,
		  lat: parseFloat(destinationPlace.lat),
		  lng: parseFloat(destinationPlace.lng),
		  destLat: parseFloat(destinationPlace.lat),
		  destLng: parseFloat(destinationPlace.lng),
		};
		parentEmps.push(newDestinationPlace);
		setParentPlaces(parentEmps);
	  }
	}
  };

  const updateParentMarkers = (parents: []) => {
	const newMarkers: [] = [];
	for (let i = 0; i < parents.length; i++) {
	  const parentUid = parents[i].element_id;
	  const parentData = elements.find(ele => ele.uid === parentUid);
	  if (parentData) {
	    if (parentData.coordinates.length > 1) {
		  const newMarker = {
			label: parentData.title,
			description: parentData.address,
			family: parentData.subType,
			value: parentData.elementId,
			lat: parseFloat(parentData.coordinates[0]),
			lng: parseFloat(parentData.coordinates[1]),
		  };
		  newMarkers.push(newMarker);
		}
	  } else {
		const empData = places.find(ele => ele.value === parentUid);
		if (empData) {
		  const newEmp = {
			label: empData.label,
			description: empData.description,
			family: 'emp',
			value: empData.value,
			lat: parseFloat(empData.lat),
			lng: parseFloat(empData.lng),
		  };
		  newMarkers.push(newEmp);
		}
	  }
	}
	if (newMarkers.length > 0) {
	  setParentMarkers(newMarkers);
	}
  };

  const updateSegment = (capacity: number, subType: string, parentUid: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	if (parent) {
	  if (parentSubType !== parent.subType || segmentSubLinks.length !== capacity) {
	    var segmentName = 'hilo';
        var segmentLabel = 'fiber';
        if (parent.subType === 're_path' || parent.subType === 'sa_path') {
          const segmentName = 'radio';
          const segmentLabel = 'channel';
        } else if (parent.subType === 'ge_path') {
          const segmentName = 'par';
          const segmentLabel = 'channel';
        }
        const newSegmentSubLinks: [] = [];
        for (let i = 0; i < capacity; i++) {
	      let segmentSubLink = {
	        label: `${segmentLabel}_${i + 1}`,
	        description: `${element.title} ${segmentName} ${i + 1}`,
	        value: `${element.segmentId}_${segmentLabel}_${i + 1}`,
	      };
	      newSegmentSubLinks.push(segmentSubLink);
        }
        setParentSubType(parent.subType);
	    setSegmentSubLinks(newSegmentSubLinks);
	  }
	}
  };
  
  const elementsRelations: [] = [];
  for (let i = 0; i < pathEvents.length; i++) {
	const newElement = elements.find(ele => ele.uid === pathEvents[i].value);
	if (newElement) {
	  let markerDidExist = elementsRelations.find(ele => ele.elementId === newElement.uid);
	  if (!markerDidExist) {
	    const relationIds: [] = newElement.relationIds;
	    for (let x = 0; x < relationIds.length; x++) {
	      const parentData = elements.find(ele => ele.uid === relationIds[x]);
		  if (parentData) {
	        if (parentData.coordinates.length > 1) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: newElement.subType,
			    value: null,
			    parentId: parentData.elementId,
				lat: null,
			    lng: null,
			    destLat: parseFloat(parentData.coordinates[0]),
			    destLng: parseFloat(parentData.coordinates[1]),
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}	
	      } else {
		    const empData = places.find(ele => ele.value === relationIds[x]);
		    if (empData) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: 'emp',
			    value: empData.value,
				parentId: empData.value,
			    lat: null,
			    lng: null,
			    destLat: parseFloat(empData.lat),
			    destLng: parseFloat(empData.lng),
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}
	      }
		}
	  }
	  if (!markerDidExist) {
	    const elementMarker = {
		  elementId: newElement.elementId,
		  pathId: newElement.pathId,
		  label: newElement.title,
		  description: newElement.address,
		  family: newElement.subType,
		  value: newElement.elementId,
		  parentId: null,
		  lat: parseFloat(newElement.coordinates[0]),
		  lng: parseFloat(newElement.coordinates[1]),
		  destLat: parseFloat(newElement.coordinates[0]),
		  destLng: parseFloat(newElement.coordinates[1]),
	    };
	    elementsRelations.push(elementMarker);
	  }
	}
  }

  return (
	<div className={styles.mapWrapper}>	  
	  <div className={element ? styles.mapContainerHalf : styles.mapContainerFull}>
		<MapComponent 
		  currentNodes={[element]}
			onImportData={(newElements) => {
			  console.log(newElements);
			  saveImportData(newElements, elements.length).then((resultado) => {
			    if (resultado !== null) {
				  console.log('Todo ok: ', resultado);
				  const elementsUpdated = updateImportElements(elements, resultado);
				  console.log(elementsUpdated);
				  onImportData(elementsUpdated);
				  onCancel();
				} else {
				  onCancel();
				}
			  }).catch((error) => {
				console.error('Error al guardar el elemento:', error);
				onCancel();
			  });
			}}
		  onCoordinatesChange={(coords, currentPlace) => {
			handlePlaceCoordsChange(coords, currentPlace);
		  }}
		  onSelectionChange={(currentUid) => {
			console.log(element, currentUid);
			if (!element || element === null) {
			  onSelect(currentUid);
			} else if (element && element.uid !== currentUid) {
			  onCancel();
			  onSelect(currentUid);
			}
		  }}
		  markers={[...parentPlaces, ...elementsRelations]}
		  items={items}
		  showMarkerIcons={true}
		  mapSource={pluginVariables[4]}
		  geoVariables={[pluginVariables[5], pluginVariables[6], pluginVariables[7], pluginVariables[8], pluginVariables[9], pluginVariables[1]]}
		  readOnly={false}
		  tableMode={false}
		  isKmzAddMode={isKmzAddMode}
		/>
	  </div>
	  {element && (
		<div className={styles.expandedContentRow}>
		  <L1OyMExpandedForm
			onCancel={() => {
			  onSelect(null);
			  onCancel();
			}}
			onSave={onSave}
			element={element}
			isDark={isDark}
			isAdmin={isAdmin}
			width={width}
			elements={elements}
			places={places}
			types={types}
			pluginVariables={pluginVariables}
			tableMode={false}
		  />
		</div>
	  )}
	</div>
  );
};

const getStyles = (isDark: boolean, width: number, height: number) => {
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    mapWrapper: css`
	  display: inline-flex;
      flex-flow: wrap;
      padding: 5px;
      align-content: flex-start;
      justify-content: space-between;
    `,
	mapContainerHalf: css`
	  width: ${width * 0.48}px;
	  min-width: 250px;
	  height: ${height * 0.9}px;
      margin-top: 5px;
      margin-left: 10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
	  margin-right: 15px;
    `,
	mapContainerFull: css`
	  width: ${width * 0.98}px;
	  min-width: 250px;
	  height: ${height * 0.9}px;
      margin-top: 5px;
      margin-left: -5px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    expandedContentRow: css`
	  padding: 0 ${theme.spacing(1)} 0 ${theme.spacing(3)};
      position: relative;
	  transition: width 0.5s ease, margin-left 0.5s ease; 

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    del_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
