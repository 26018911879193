import React, { FC, useState, useEffect, useRef } from 'react';
import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownPreview from '@uiw/react-markdown-preview';
import DOMPurify from 'dompurify';

interface ContentEditorProps {
  element: HelpData;
  onInactivity: (content: string) => void;
  readOnly: boolean;
  tableMode: boolean;
  height: number;
  width: number;
  sanitice: boolean;
  enablePreview: boolean;
  previewOnly: boolean;
}

export const ContentEditor: FC<ContentEditorProps> = ({ 
  element,
  onInactivity,
  readOnly,
  tableMode,
  height,
  width,
  sanitice,
  enablePreview,
  previewOnly,
}) => {
  let markdownContent = '';
  try {
    markdownContent = JSON.parse(element.content).markdownContent || '';
  } catch (error) {
    markdownContent = 'Error al obtener datos';
  }
  const [markdownText, setMarkdownText] = useState(element.content);
  const [isTyping, setIsTyping] = useState(false);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (value: string | undefined) => {
	try {
	  const sanitizedValue = DOMPurify.sanitize(value || "", { 
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p'], 
        ALLOWED_ATTR: ['href', 'title', 'target']
      });
	  setMarkdownText(sanitice ? sanitizedValue : value);
      setIsTyping(true);
      setErrorMessage('');
    } catch (error) {
      console.error("Error al pegar contenido:", error);
      setErrorMessage("El contenido insertado tiene caracteres o etiquetas no permitidas");
    }
  };

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
		const contentScaped = JSON.stringify({ markdownContent: markdownText });
		onInactivity(contentScaped);
        setIsTyping(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [markdownText, isTyping]);

  if (!previewOnly && !readOnly) {
    return (
      <div style={{ height: height - 15 }}>
	    <MarkdownEditor
          ref={editorRef}
		  value={markdownText}
		  onChange={(editor, data, value) => handleChange(value)}
		  height={`${height - 20}px`}
		  enablePreview={enablePreview}
		  enableScroll={true}
        />
	    {errorMessage !== '' && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    );  
  } else {
    return (
      <div>
	    <MarkdownPreview 
          ref={editorRef}
		  source={markdownText}
        />
	    {errorMessage !== '' && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    );
  }
};