import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { L1OyMData } from './L1OyMManager';
import { L1OyMGroup } from './L1OyMGroup';
import { valueToSubtype } from './types';

export const l1OyMManagerPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const userName = contextSrv.user.name;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen elementos';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);
  const mapSource = replaceVariables(options.mapSource);
  const placeVariable = replaceVariables(options.placeVariable);
  const placeTypesVariable = replaceVariables(options.placeTypesVariable);
  const pluginVariables = [
    pluginApi,
	placeVariable,
	placeTypesVariable,
	noAlertMessage,
	mapSource,
	options.useZoomVariable,
	options.zoomVariable,
	options.xVariable,
	options.yVariable,
	options.uidVariable,
  ];
  const [elementsConfigured, setElementsConfigured] = useState<L1OyMData[]>([]);
  const [valueTracked, setValueTracked] = useState('');
  const [renderCount, setRenderCount] = useState(0);

  const memoizedOptions = useMemo(() => ({ 
    data: data,
	filterToTrack: replaceVariables(options.variableToTrack),
  }), [data, options, replaceVariables]);
  const filterUsed = memoizedOptions.filterToTrack;
  const styles = useStyles2(getStyles(width, height));

  const subTypeToType = (value: string) => {
	const valueSelected = valueToSubtype.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return 'Unknown';
	}
  }

  const placeList: [] = [];
  const placeVariableValue = replaceVariables(options.placeList);
  const places = placeVariableValue.split(',');

  for (let i = 0; i < places.length; i++) {
	const placeData = places[i].split('||');
	let place = {
	  label: placeData[0],
	  description: placeData[1],
	  family: placeData[2],
	  value: placeData[3],
	  lat: placeData[4],
	  lng: placeData[5],
	};
	placeList.push(place);
  }

  const placeTypeList: [] = [];
  const typesVariableValue = replaceVariables(options.placeTypeList);
  const placeTypes = typesVariableValue.split(',');

  for (let i = 0; i < placeTypes.length; i++) {
	const placeData = placeTypes[i].split('||');
	let placeType = {
	  label: placeData[0],
	  description: placeData[1],
	  value: placeData[2],
	};
	placeTypeList.push(placeType);
  }

  const newElementsConfigured: L1OyMData[] = [];
  memoizedOptions.data.series.forEach(series => {
	const rulesVals: GraphSeriesValue[] = series.fields[0] ? series.fields[0].values.toArray() : [];
	for (let i = 0; i < rulesVals.length; i++) {
	  let newElement = {
		id: i,
		idx: '',
		uid: series.fields.find(field => field.name === options.uid)?.values.get(i),
		title: series.fields.find(field => field.name === options.title)?.values.get(i),
		elementId: '',
		pathId: '',
		linkId: '',
		status: 'disable',
		state: false,
		installationDate: '',
		installationNotes: '',
		technician: '',
		lastInspection: '',
		nextMaintenance: '',
		owner: '',
		elementType: '',
		subType: '',
		technicalDetails: series.fields.find(field => field.name === options.technicalDetails)?.values.get(i),
		origin: '',
		destination: '',
		coordinates: [],
		address: '',
		elevation: 0,
		parentsIds: [],
		relationIds: [],
		relationDistances: [],
		relationCapacities: [],
		sublinkIds: [],
	    sublinkBuffer: [],
	    sublinkColor: [],
		sublinkAttenuation: [],
		sublinkDistance: [],
		segmentId: '',
		pathEventId: '',
		capacity: 0,
		fiberIds: [],
		fiberAttenuation: [],
		lastInspector: '',
		inspectionReport: '',
		inspectionNotes: '',
		inspectionMediaData: '',
		user: series.fields.find(field => field.name === options.user)?.values.get(i),
		created: series.fields.find(field => field.name === options.created_at)?.values.get(i),
		updated: series.fields.find(field => field.name === options.updated_at)?.values.get(i),
		version: Number(series.fields.find(field => field.name === options.version)?.values.get(i)),
		wasChange: false,
	  };
	  const jsonObject = JSON.parse(String(newElement.technicalDetails));
	  newElement.idx = jsonObject.id;
	  newElement.status = jsonObject.technical_details.status;
	  newElement.state = newElement.status === 'disable' ? false : true;
	  newElement.subType = jsonObject.type;
	  const elementType = subTypeToType(newElement.subType);
	  newElement.elementType = elementType;
	  if (elementType === 'path') {
	    newElement.pathId = jsonObject.id;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.lastInspection = jsonObject.technical_details.last_inspection;
		newElement.nextMaintenance = jsonObject.technical_details.next_maintenance;
		newElement.owner = jsonObject.technical_details.owner;
		newElement.origin = jsonObject.technical_details.origin;
		newElement.destination = jsonObject.technical_details.destination;
		if (jsonObject.technical_details.geometry) {
		  const coordinates = jsonObject.technical_details.geometry.coordinates ? jsonObject.technical_details.geometry.coordinates : [];
		  newElement.coordinates = coordinates;
		}
	  } if (elementType === 'link') {
	    newElement.linkId = jsonObject.id;
		newElement.pathId = jsonObject.technical_details.path_id;
		newElement.parentsIds = jsonObject.technical_details.parents;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.lastInspection = jsonObject.technical_details.last_inspection;
		newElement.nextMaintenance = jsonObject.technical_details.next_maintenance;
		newElement.owner = jsonObject.technical_details.owner;
		newElement.origin = jsonObject.technical_details.origin;
		newElement.destination = jsonObject.technical_details.destination;
	  } else if (elementType === 'element') {
	    newElement.elementId = jsonObject.id;
		newElement.pathId = jsonObject.technical_details.path_id;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.lastInspection = jsonObject.technical_details.last_inspection;
		newElement.nextMaintenance = jsonObject.technical_details.next_maintenance;
		newElement.owner = jsonObject.technical_details.owner;
		newElement.coordinates = jsonObject.technical_details.location.coordinates;
		newElement.address = jsonObject.technical_details.location.address;
		newElement.elevation = jsonObject.technical_details.location.elevation;
		newElement.parentsIds = jsonObject.technical_details.parents;
		if (jsonObject.technical_details.relations) {
		  newElement.relationIds = jsonObject.technical_details.relations.map(sublink => sublink.element_id);
		  newElement.relationDistances = jsonObject.technical_details.relations.map(sublink => sublink.distances);
		  newElement.relationCapacities = jsonObject.technical_details.relations.map(sublink => sublink.capacities);
		}
	  } else if (elementType === 'segment') {
		newElement.segmentId = jsonObject.id;
		newElement.linkId = jsonObject.technical_details.link_id;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.lastInspection = jsonObject.technical_details.last_inspection;
		newElement.nextMaintenance = jsonObject.technical_details.next_maintenance;
		newElement.owner = jsonObject.technical_details.owner;
		newElement.segmentType = jsonObject.technical_details.segment_type;
		newElement.capacity = Number(jsonObject.technical_details.capacity);
		newElement.parentsIds = jsonObject.technical_details.parents;
		newElement.origin = jsonObject.technical_details.origin;
		newElement.destination = jsonObject.technical_details.destination;
		newElement.sublinkIds = jsonObject.technical_details.sublinks.map(sublink => sublink.sublink_id);
		newElement.sublinkBuffer = jsonObject.technical_details.sublinks.map(sublink => sublink.buffer);
		newElement.sublinkColor = jsonObject.technical_details.sublinks.map(sublink => sublink.color);
		newElement.sublinkAttenuation = jsonObject.technical_details.sublinks.map(sublink => sublink.attenuation);
		newElement.sublinkDistance = jsonObject.technical_details.sublinks.map(sublink => sublink.distance);
	  } else if (elementType === 'splicer') {
	    newElement.elementId = jsonObject.id;
		newElement.segmentId = jsonObject.technical_details.segment_id;
		newElement.pathEventId = jsonObject.technical_details.path_event_id;
		newElement.parentsIds = jsonObject.technical_details.parents;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.capacity = Number(jsonObject.technical_details.capacity);
		newElement.fiberIds = jsonObject.technical_details.splicings.map(splice => splice.fiber_id);
		newElement.eventAttenuation = jsonObject.technical_details.splicings.map(splice => splice.attenuation);
		newElement.technician = jsonObject.technical_details.technician;
	  } else if (elementType === 'splitter') {
	    newElement.elementId = jsonObject.id;
		newElement.segmentId = jsonObject.technical_details.segment_id;
		newElement.pathEventId = jsonObject.technical_details.path_event_id;
		newElement.parentsIds = jsonObject.technical_details.parents;
		newElement.installationDate = jsonObject.technical_details.installation_date;
		newElement.installationNotes = jsonObject.technical_details.notes;
		newElement.capacity = Number(jsonObject.technical_details.capacity);
		newElement.fiberIds = jsonObject.technical_details.splittings.map(splice => splice.fiber_id);
		newElement.eventAttenuation = jsonObject.technical_details.splittings.map(splice => splice.attenuation);
		newElement.technician = jsonObject.technical_details.technician;
	  }
	  newElement.lastInspector = jsonObject.inspection_data.last_inspector;
	  newElement.inspectionReport = jsonObject.inspection_data.report;
	  newElement.inspectionNotes = jsonObject.inspection_data.notes;
	  newElement.inspectionMediaData = jsonObject.media.images;
	  const created_at = newElement.created;
	  newElement.created = moment(created_at).format('DD/MM/YYYY HH:mm:ss');
	  const updated_at = newElement.updated;
	  newElement.updated = moment(updated_at).format('DD/MM/YYYY HH:mm:ss');
	  newElementsConfigured.push(newElement);
	}
  });

  if (renderCount === 0 || valueTracked !== memoizedOptions.filterToTrack) {
    setElementsConfigured(newElementsConfigured);
	setValueTracked(memoizedOptions.filterToTrack);
	setRenderCount(renderCount + 1);
  }

  const noAlertsMessage = elementsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <L1OyMGroup
	    id={id + '_' + renderCount}
	    showTitle={options.showTitle}
		showSelector={options.showAssetSelector}
		showTable={options.showTableMode}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    elements={elementsConfigured}
		places={placeList}
		types={placeTypeList}
		pluginVariables={pluginVariables}
		onSaveChanges={(elementSaved) => {
		  const actualElements = elementsConfigured;
		  const element = elementSaved.find(element => element.updated === '');
		  const index = element ? element.id : null;
		  if (index !== null) {
			const d = Date.now();
			const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
			let newElement = element;
			newElement.updated = date;
			actualElements[index] = newElement;
		    setElementsConfigured(actualElements);			
		  }
		  return actualElements;
		}}
		onImportData={(newElements) => {
		  console.log(newElements);
		  setElementsConfigured(newElements);
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const getStyles = (width: number, height: number) => {
  return (theme: NetMonitorTheme2) => ({
    section: css`
      margin: 4px;
	  width: ${width}px;
	  height: ${height}px;
    `,
  });
};
