import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect, useMemo } from 'react'; 
import { NetMonitorTheme2 } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Label,
  Form,
  HorizontalGroup,
  VerticalGroup,
  Input,
  InputControl,
  Select,
  Switch,
  TextArea,
  useStyles2,
} from '@grafana/ui';

import { CollapsibleSection } from './CollapsibleSection';
import { HelpData } from './HelpManager';
import { ContentEditor } from './ContentEditor';
import { valueToType, valueToSubtype, valueToStatus } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface HelpExpandedTocProps {
  element: HelpData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  height: number;
  elements: HelpData[];
  pluginVariables: [];
}

export const HelpExpandedToc: FC<HelpExpandedTocProps> = ({ 
  element, 
  isDark, 
  isAdmin,
  width,
  height,
  elements,
  pluginVariables,
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  return (
    <Form 
	  defaultValues={element} 
	  onSubmit={(value) => handleSubmit(value)} 
	  className={styles.tocContainer}
	>
      {({ control, register, watch, setValue }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  {!pluginVariables[7] && (
		    <legend className={styles.labelStyle} >
			  {watch().section + ' - ' + watch().chapter + ': ' + watch().title}
		    </legend>
		  )}
		  <div className={styles.markdownViewer} data-color-mode={isDark ? 'dark' : 'light'}>
			<ContentEditor 
			  element={element}
			  readOnly={true}
			  height={height}
			  width={width}
			  sanitice={pluginVariables[3]}
			  enablePreview={true}
			  previewOnly={true}
			/>
		  </div>
        </>
      )}
    </Form>
  );
};

export interface HelpExpandedFormProps {
  onCancel: () => void;
  onSave: (data: HelpData[]) => void;
  element: HelpData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  elements: HelpData[];
  pluginVariables: [];
}

export const HelpExpandedForm: FC<HelpExpandedFormProps> = ({ 
  onCancel, 
  onSave,
  element, 
  isDark, 
  isAdmin,
  width, 
  elements,
  pluginVariables,
}) => {
  const isNetMonitorAdmin = contextSrv.isNetMonitorAdmin;
  const styles = useStyles2(getStyles(isDark, width));
  const contentText = typeof element.content === 'string' ? element.content : JSON.stringify(element.content);
  const [markdown, setMarkdown] = useState(contentText);
  var validSubTypes = isNetMonitorAdmin ? valueToSubtype.filter(value => value.family === element.docType) :
	valueToSubtype.filter(value => value.family === element.docType && value.showToViewer === true);

  const handleSubmit = (value: any) => {
    let valuesToUpdate = value;
	valuesToUpdate.status = value.state === false ? 'disable' : value.status;
	valuesToUpdate.content = markdown;
	onSave(valuesToUpdate, 'full');
  };

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const updateValidSubTypes = (typeSelected: string) => {
	validSubTypes = isNetMonitorAdmin ? valueToSubtype.filter(value => value.family === typeSelected) :
	  valueToSubtype.filter(value => value.family === typeSelected && value.showToViewer === true);
  }

  const subtypeToString = (value: string) => {
	const valueSelected = validSubTypes.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  return (
    <Form 
	  defaultValues={element} 
	  onSubmit={(value) => handleSubmit(value)} 
	  className={styles.formContainer}
	>
      {({ control, register, watch, setValue }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  {!pluginVariables[5] && !pluginVariables[9] && isAdmin && (
		    <HorizontalGroup key={'status'} align="flex-start" height="55px"> 
		      <Field label="Habilitar" className={styles.switchContainer}>
                <Switch id="state" className={styles.switchStyle} {...register('state')} />
              </Field>
			  {watch().state && (
			    <>
				  <Field 
				    label="Estado administrativo:"
				    className={styles.nameContainer}
			      >
				    <InputControl
				      render={({ field: { onChange, ref, ...field } }) => (
					    <Select
					      aria-label="status"
					      {...field}
					      className={styles.select}
					      onChange={(value) => onChange(mapSelectValueToString(value))}
					      options={isNetMonitorAdmin ? valueToStatus : valueToStatus.filter(ele => ele.showToViewer === true)}
					      defaultValue={statusToString(field)}
					      title="Estado administrativo del documento que esta modicando"
					    />
				      )}
				      control={control}
				      name="status"
				    />
			      </Field>
			      <Field 
				    label="Tipo:"
				    className={styles.nameContainer}
			      >
				   <InputControl
				      render={({ field: { onChange, ref, ...field } }) => (
					    <Select
					      aria-label="docType"
					      {...field}
					      className={styles.select}
					      onChange={(value) => {
					        updateValidSubTypes(value.value);
						    onChange(mapSelectValueToString(value));
						    const firstValidSubtype = validSubTypes[0]?.value || '';
						    setValue('subType', firstValidSubtype);
					      }}
					      options={isNetMonitorAdmin ? valueToType : valueToType.filter(ele => ele.showToViewer === true)}
					      defaultValue={typeToString(field)}
					      title="Tipo de documento que esta definiendo"
					    />
				      )}
				      control={control}
				      name="docType"
				    />
			      </Field>
			      <Field 
				    label="Subtipo:"
			        className={styles.nameContainer}
			      >
				    <InputControl
				      render={({ field: { onChange, ref, ...field } }) => (
				        <Select
					      aria-label="subType"
					      {...field}
					      className={styles.select}
					      onChange={(value) => {
						    onChange(mapSelectValueToString(value));
					      }}
					      options={validSubTypes}
					      defaultValue={subtypeToString(field)}
					      title="subTipo de documento que esta definiendo"
					    />
				      )}
				      control={control}
				      name="subType"
				    />
			      </Field>
				</>
		      )}
		    </HorizontalGroup>
		  )}
		  {!pluginVariables[5] && !pluginVariables[9] && !isAdmin && watch().state && (
			  <HorizontalGroup key={'status'} align="flex-start" height="55px">
			    <div>
				  <legend className={styles.legendStyle} >{'Estado administrativo:'} </legend>
				  <Input
					aria-label="status"
					className={styles.input}
					value={statusToString(watch().status)}
					type="text"
					title="Estado administrativo del documento"
					readOnly={true}
				  />
				</div>
				<div>
				  <legend className={styles.legendStyle} >{'Tipo:'} </legend>
				  <Input
					aria-label="docType"
					className={styles.input}
					value={typeToString(watch().docType)}
					type="text"
					title="Tipo de documento que esta definiendo"
					readOnly={true}
				  />
				</div>
				<div>
				  <legend className={styles.legendStyle} >{'Subtipo:'} </legend>
				  <Input
					aria-label="subType"
					className={styles.input}
					value={subtypeToString(watch().subType)}
					type="text"
					title="subTipo de documento que esta definiendo"
					readOnly={true}
				  />
				</div>
			  </HorizontalGroup>
		  )}
		  {watch().state && watch().docType === 'help' && !pluginVariables[5] && (
			<HorizontalGroup key={'type'} align="flex-start" height="55px">
			  <Field 
				label="Sección:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field }) => (
					<Input
					  aria-label="section"
					  {...field}
					  className={styles.input}
					  type="text"
					  title="Nombre de la sección a la que pertenece el documento"
					  readOnly={!isAdmin}
					/>
				  )}
				  control={control}
				  name="section"
				/>
			  </Field>
			  <Field 
				label="Capítulo:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field }) => (
					<Input
					  aria-label="chapter"
					  {...field}
					  className={styles.input}
					  type="text"
					  title="Nombre del capítulo a la que pertenece el documento"
					  readOnly={!isAdmin}
					/>
				  )}
				  control={control}
				  name="chapter"
				/>
			  </Field>
		    </HorizontalGroup>
		  )}
		  <HorizontalGroup key={'main'} align="flex-start" height="55px"> 
			{pluginVariables[9] || (watch().docType === 'notes' && watch().subType === 'alarm') && (
			  <Field 
			    label="Id de Alerta/Alarma:"
			    className={styles.nameContainer}
			  >
			    <InputControl
			      render={({ field }) => (
                    <Input
                      aria-label="alarmId"
                      {...field}
				      className={styles.input}
				      type="text"
					  placeholder="Ingrese un identificador..."
					  title="ID de la Alerta/Alarma asociada a esta nota"
					  readOnly={true}
                    />
				  )}
				  control={control}
				  name="alarmId"
			  />
			  </Field>
			)}
			<Field 
			  label="Título:"
			  className={styles.nameContainer}
			>
			  <InputControl
			    render={({ field }) => (
                  <Input
                    aria-label="title"
                    {...field}
				    className={styles.input}
				    type="text"
					placeholder="Ingrese un identificador..."
					title="Nombre o alias asignado al documento"
					readOnly={pluginVariables[5] || !isAdmin}
                  />
				)}
				control={control}
				name="title"
			/>
			</Field>
		  </HorizontalGroup>
		  {watch().state && (
		    <HorizontalGroup key={'editor'} align="flex-start" height="380px"> 
			  <div className={styles.markdownContainer} data-color-mode={isDark ? 'dark' : 'light'}>
				<ContentEditor 
				  element={element}
				  onInactivity={(content) => {
					setMarkdown(content);
				  }}
				  readOnly={false}
				  height={350}
				  sanitice={pluginVariables[3]}
				  enablePreview={pluginVariables[4]}
				  previewOnly={pluginVariables[5] || !isAdmin}
				/>
			  </div>
			</HorizontalGroup>
		  )}
		  {watch().state && (watch().docType === 'help' || isAdmin) && !pluginVariables[5] && (!pluginVariables[9] || isAdmin) && (
			<HorizontalGroup key={'content'} align="flex-start" height="100px">
			  <Field 
		        label="Notas de la edición:"
			    className={styles.textContainer}
			  >
			    <InputControl
				  render={({ field }) => (
				    <TextArea
					  aria-label="editionNotes"
					  {...field}
					  className={styles.textArea}
					  rows="2"
					  placeholder="Notas de esta edición"
					  title="Notas relacionadas con el documento o sus modificaciones"
					  readOnly={!isAdmin}
				    />
				  )}
				  control={control}
				  name="editionNotes"
			    />
			  </Field>
		    </HorizontalGroup>
		  )}
		  {!pluginVariables[5] && (isAdmin || (watch().docType === 'notes' && pluginVariables[8])) && (
		    <div className={styles.buttonGroup}>
		      {isAdmin && (
			    <button 
			      type="submit"
			      className={styles.okButton} 
			      title={'Guardar modificaciones'}
			    >
			      <div className={styles.del_icon}>
			        <Icon name={'save'} size="lg" />
			      </div>
			      {'Guardar'}
			    </button>
			  )}
			  <button 
			    type="button" 
			    className={styles.cancelButton} 
			    onClick={onCancel}
			    title={isAdmin ? 'Cerrar sin modificar' : 'Cerrar'}
			  >
			    <div className={styles.del_icon}>
			      <Icon name="times-fill" size="lg" />
			    </div>
			    {'Cerrar'}
			  </button>
            </div>
		  )}
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const testButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    markdownContainer: css`
	  width: ${width - 100}px;
	  min-width: 350px;
	  height: 365px;
      margin-top: 5px;
	  margin-bottom: 5px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
	formContainer: css`
      max-width: 100%;
	  border-top: 1px solid ${buttonBorder};
      margin-top: 5px;
      padding-top: 10px;
      margin-left: -10px;
    `,
	tocContainer: css`
      margin-top: 14px;
    `,
    markdownViewer: css`
	  width: 100%;
	  height: 100%;
      margin-top: -5px;
	  border-top: 1px solid ${buttonBorder};
      padding-top: 5px;
    `,
    nameContainer: css`
      width: ${width * 0.2}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    textContainer: css`
      width: ${width * 0.9}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    switchContainer: css`
      width: ${width * 0.2}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    addButton: css`
      margin: 0px;
	  width: 100%;
	  align-items:right;
      & > * + * {
        margin-right: 8px;
      }
    `,
    legendStyle: css`
	  color: ${textColor};
	  font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
	  margin-bottom: 4px;
	  -webkit-box-align: center;
      align-items: center;
	  max-width: 480px;
    `,
    labelStyle: css`
	  color: ${okButtonColor};
	  margin-bottom: 10px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    switchStyle: css`
	  width: 32px;
      height: 16px;
      position: relative;
	  top: 4px;
    `,
    select: css`
      flex: 1;
		: 90%;
	  max-width: 350px;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 14px;
    `,
    textArea: css`
      flex: 1;
      width: 100%;
	  margin-top: 2px;
	  font-size: 14px;
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button: css`
      background-color: transparent;
      border: none;
      color: ${textColor};
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 4px 0px 4px;
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    del_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
