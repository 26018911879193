import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect  } from 'react'; 
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Label,
  Form,
  HorizontalGroup,
  VerticalGroup,
  Input,
  InputControl,
  Select,
  MultiSelect,
  Switch,
  TextArea,
  Collapse,
  useStyles2,
} from '@grafana/ui';
import { CollapsibleSection } from './CollapsibleSection';
import { L1OyMData } from './L1OyMManager';
import { MapComponent } from './PointPickerModal';
import { valueToType, valueToSubtype, valueToStatus, fiberOpticColors, bufferColors, multiparColors } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface L1OyMExpandedFormProps {
  onCancel: () => void;
  onSave: (data: L1OyMData[]) => void;
  element: L1OyMData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  pluginVariables: [];
  tableMode: boolean;
}

export const L1OyMExpandedForm: FC<L1OyMExpandedFormProps> = ({ 
  onCancel, 
  onSave,
  element, 
  isDark, 
  isAdmin,
  width, 
  elements,
  places,
  types,
  pluginVariables,
  tableMode
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const [isOpen, setIsOpen] = useState(false);
  const [relations, setRelations] = useState([]);
  const [validSubTypes, setValidSubTypes] = useState([]);
  const [linkIDs, setLinkIDs] = useState([]);
  const [sublinks, setSublinks] = useState([]);
  const [parentSubType, setParentSubType] = useState('path');
  const [segmentSubLinks, setSegmentSubLinks] = useState([]);
  const [linkCapacity, setLinkCapacity] = useState(element.capacity);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [coordinates, setCoordinates] = useState(element.coordinates);
  const [parentPlaces, setParentPlaces] = useState([]);
  const [pathEventRelations, setPathEventRelations] = useState([]);
  const [parentMarkers, setParentMarkers] = useState([]);

  const [isPathOpen, setIsPathOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isParentOpen, setIsParentOpen] = useState(false);
 
  //console.log(element, elements, places);
  const handlePlaceCoordsChange = (coords, elementId) => {
	if (elementId && elementId !== null) {
	  const element = elements.find(ele => ele.uid === elementId);
	  console.log(element);
      if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
	    element.coordinates[0] = parseFloat(coords[0]);
	    element.coordinates[1] = parseFloat(coords[1]);
		element.wasChange = true;
	  }
	}
	setCoordinates(coords);
  };

  const handleCoordsChange = (e) => {
	const value = e.split(',').map(Number);
    if (value.length === 2 && !isNaN(value[0]) && !isNaN(value[1])) {
      setCoordinates(value);
	  return true;
    }
  };
  
  const handleAddSublink = () => {
    setSublinks([...sublinks, { sublinkId: '', buffer: '', color: '', distance: 0, attenuation: 0 }]);
  };

  const handleRemoveSublink = (index) => {
    setSublinks(sublinks.filter((_, i) => i !== index));
  };

  const handleChangeSublink = (index, field, value) => {
    const newSublinks = [...sublinks];
    newSublinks[index][field] = value;
    setSublinks(newSublinks);
  };

  const handleAddParent = () => {
    setRelations([...relations, { element_id: '', distance: '', capacity: '' }]);
  };

  const handleRemoveParent = (index) => {
    setRelations(relations.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newParents = [...relations];
    newParents[index][field] = value;
    setRelations(newParents);
	if (newParents[index].element_id !== '') {
	  updateParentMarkers(newParents);
	}
  };

  const handleSubmit = (value: any) => {
    let valuesToUpdate = value;
	valuesToUpdate.status = value.state === false ? 'disable' : value.status;
	if (value.elementType === 'path') {
	  const elementsChanged = elements.filter(ele => ele.wasChange === true);
	  elementsChanged.forEach(element => {
		const valueToUpdate = element;
		onSave(valueToUpdate, 'coordinates');
		element.wasChange = false;
	  });
	  valuesToUpdate.pathId = value.idx;
	  onSave(valuesToUpdate, 'full');
	} else if (value.elementType === 'link') {
	  valuesToUpdate.linkId = value.idx;
	  onSave(valuesToUpdate, 'full');
	} else {
	  valuesToUpdate = formatToSubmit(value);
	  onSave(valuesToUpdate, 'full');
	}
  };

  const formatToSubmit = (value: any) => {
    let valueFormated = value;
	if (value.elementType === 'element') {
	    valueFormated.elementId = value.idx;
	    valueFormated.coordinates = coordinates;
	    const relationIds = relations.map(p => p.element_id);
        const distances = relations.map(p => parseFloat(p.distance) || 0);
        const capacities = relations.map(p => parseFloat(p.capacity) || 0);
	    valueFormated.relationIds = relationIds;
	    valueFormated.relationDistances = distances;
	    valueFormated.relationCapacities = capacities;
	  } else if (value.elementType === 'segment') {
	    if (parentSubType === 'fo_path' || parentSubType === 'ge_path') {
	      valueFormated.capacity = linkCapacity;
	      valueFormated.segmentId = value.idx;
	    }
	    const sublink_ids = sublinks.map(p => p.sublinkId);
        const sublink_buffer = sublinks.map(p => p.buffer || '');
        const sublink_color = sublinks.map(p => p.color || '');
        const sublink_distances = sublinks.map(p => parseFloat(p.distance) || 0);
        const sublink_attenuation = sublinks.map(p => parseFloat(p.attenuation) || 0);
	    valueFormated.sublinkIds = sublink_ids;
	    valueFormated.sublinkBuffer = sublink_buffer;
	    valueFormated.sublinkColor = sublink_color;
	    valueFormated.sublinkDistance = sublink_distances;
	    valueFormated.sublinkAttenuation = sublink_attenuation;
	  } else {
	    valueFormated.elementId = value.idx;
	  }
	return valueFormated;
  }

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const updateValidSubTypes = (typeSelected: string) => {
	const validTypes = valueToSubtype.filter(value => value.family === typeSelected)
	setValidSubTypes(validTypes);
  }

  const subtypeToString = (value: string) => {
	const valueSelected = validSubTypes.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const placeToString = (value: string) => {
	const valueSelected = places.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const pathIDs: [] = [];
  const idList = elements.filter(value => value.elementType === 'path' && value.version > 0);

  for (let i = 0; i < idList.length; i++) {
	let newId = {
	  label: idList[i].title,
	  description: idList[i].title,
	  value: idList[i].pathId,
	};
	pathIDs.push(newId);
  }

  const updateLinks = (subType: string) => {
	const newLinksIds: [] = [];
	let linkList = elements.filter(value => value.elementType === 'link' && (value.subType === 'fo_path' || value.subType === 'ge_path'));
	if (subType === 'radio') {
      linkList = elements.filter(value => value.elementType === 'link' && (value.subType === 're_path' || value.subType === 'sa_path'));
	}

    for (let i = 0; i < linkList.length; i++) {
	  let newLink = {
	    label: linkList[i].title,
	    description: linkList[i].title,
	    value: linkList[i].linkId,
	  };
	  newLinksIds.push(newLink);
    }
	setLinkIDs(newLinksIds);
  }

  const linkToString = (value: string) => {
	const valueSelected = linkIDs.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const pathEvents: [] = [];
  const events = elements.filter(value => value.elementType === 'element');

  for (let i = 0; i < events.length; i++) {
	let pathEvent = {
	  label: events[i].title,
	  description: events[i].address,
	  value: events[i].elementId,
	  family: events[i].subType,
	  lat: events[i].coordinates[0] || 0,
	  lng: events[i].coordinates[1] || 0,
	};
	pathEvents.push(pathEvent);
  }
 
  const eventToString = (value: string) => {
	const valueSelected = pathEvents.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const linkSegments: [] = [];
  const segments = elements.filter(value => value.elementType === 'segment');

  for (let i = 0; i < segments.length; i++) {
	let linkSegment = {
	  label: segments[i].title,
	  description: segments[i].title,
	  value: segments[i].elementId,
	};
	linkSegments.push(linkSegment);
  }

  const segmentToString = (value: string) => {
	const valueSelected = linkSegments.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const coordinatesToPlace = (coordinates: []) => {
	const valueSelected = places.find(ele => 
	  parseFloat(ele.lat).toFixed(6) === parseFloat(coordinates[0]).toFixed(6) && 
	  parseFloat(ele.lng).toFixed(6) === parseFloat(coordinates[1]).toFixed(6));
	if (valueSelected) {
	  return valueSelected.value;
	} else {
	 return null;
	}
  }

  useEffect(() => {
	updateValidSubTypes(element.elementType);
    if (element.elementType === 'element') {
	  const initialParents = element.relationIds.map((relationId, index) => ({
        element_id: relationId || '', 
        distance: element.relationDistances[index] || '', 
        capacity: element.relationCapacities[index] || '',
      }));
      setRelations(initialParents);
	} else if (element.elementType === 'segment') {
      const initialSubLinks = element.sublinkIds.map((sublinkId, index) => ({
        sublinkId: sublinkId || '', 
        buffer: element.sublinkBuffer[index] || '', 
        color: element.sublinkColor[index] || '',
        distance: element.sublinkDistance[index] || '', 
        attenuation: element.sublinkAttenuation[index] || '',
      }));
      setSublinks(initialSubLinks);
	}
  }, [element]);

  const updateElement = (subType: string, parentUid: string, pathId: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	const parentEmps: [] = [];
	if (parent) {
	  if (parent.origin !== '' || parent.destination !== '') {
		const originPlace = places.find(ele => ele.value === parent.origin);
		const newOriginPlace = {
		  elementId: originPlace.value,
		  pathId: pathId,
		  label: originPlace.label,
		  description: originPlace.description,
		  family: 'emp',
		  value: originPlace.value,
		  lat: parseFloat(originPlace.lat),
		  lng: parseFloat(originPlace.lng),
		  destLat: parseFloat(originPlace.lat),
		  destLng: parseFloat(originPlace.lng),
		};
		parentEmps.push(newOriginPlace);
		const destinationPlace = places.find(ele => ele.value === parent.destination);
		const newDestinationPlace = {
		  elementId: destinationPlace.value,
		  pathId: pathId,
		  label: destinationPlace.label,
		  description: destinationPlace.description,
		  family: 'emp',
		  value: destinationPlace.value,
		  lat: parseFloat(destinationPlace.lat),
		  lng: parseFloat(destinationPlace.lng),
		  destLat: parseFloat(destinationPlace.lat),
		  destLng: parseFloat(destinationPlace.lng),
		};
		parentEmps.push(newDestinationPlace);
		setParentPlaces(parentEmps);
	  }
	}
  };

  const updateParentMarkers = (parents: []) => {
	const newMarkers: [] = [];
	for (let i = 0; i < parents.length; i++) {
	  const parentUid = parents[i].element_id;
	  const parentData = elements.find(ele => ele.uid === parentUid);
	  if (parentData) {
	    if (parentData.coordinates.length > 1) {
		  const newMarker = {
			label: parentData.title,
			description: parentData.address,
			family: parentData.subType,
			value: parentData.elementId,
			lat: parseFloat(parentData.coordinates[0]),
			lng: parseFloat(parentData.coordinates[1]),
		  };
		  newMarkers.push(newMarker);
		}
	  } else {
		const empData = places.find(ele => ele.value === parentUid);
		if (empData) {
		  const newEmp = {
			label: empData.label,
			description: empData.description,
			family: 'emp',
			value: empData.value,
			lat: parseFloat(empData.lat),
			lng: parseFloat(empData.lng),
		  };
		  newMarkers.push(newEmp);
		}
	  }
	}
	if (newMarkers.length > 0) {
	  setParentMarkers(newMarkers);
	}
  };

  const updateSegment = (capacity: number, subType: string, parentUid: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	if (parent) {
	  if (parentSubType !== parent.subType || segmentSubLinks.length !== capacity) {
	    var segmentName = 'hilo';
        var segmentLabel = 'fiber';
        if (parent.subType === 're_path' || parent.subType === 'sa_path') {
          const segmentName = 'radio';
          const segmentLabel = 'channel';
        } else if (parent.subType === 'ge_path') {
          const segmentName = 'par';
          const segmentLabel = 'channel';
        }
        const newSegmentSubLinks: [] = [];
        for (let i = 0; i < capacity; i++) {
	      let segmentSubLink = {
	        label: `${segmentLabel}_${i + 1}`,
	        description: `${element.title} ${segmentName} ${i + 1}`,
	        value: `${element.segmentId}_${segmentLabel}_${i + 1}`,
	      };
	      newSegmentSubLinks.push(segmentSubLink);
        }
        setParentSubType(parent.subType);
	    setSegmentSubLinks(newSegmentSubLinks);
	  }
	}
  };
  
  const elementsRelations: [] = [];
  for (let i = 0; i < pathEvents.length; i++) {
	const newElement = elements.find(ele => ele.uid === pathEvents[i].value);
	if (newElement) {
	  let markerDidExist = elementsRelations.find(ele => ele.elementId === newElement.uid);
	  if (!markerDidExist) {
	    const relationIds: [] = newElement.relationIds;
	    for (let x = 0; x < relationIds.length; x++) {
	      const parentData = elements.find(ele => ele.uid === relationIds[x]);
		  if (parentData) {
	        if (parentData.coordinates.length > 1) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: newElement.subType,
			    value: null,
			    parentId: parentData.elementId,
				lat: null,
			    lng: null,
			    destLat: parseFloat(parentData.coordinates[0]),
			    destLng: parseFloat(parentData.coordinates[1]),
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}	
	      } else {
		    const empData = places.find(ele => ele.value === relationIds[x]);
		    if (empData) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: 'emp',
			    value: empData.value,
				parentId: empData.value,
			    lat: null,
			    lng: null,
			    destLat: parseFloat(empData.lat),
			    destLng: parseFloat(empData.lng),
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}
	      }
		}
	  }
	  if (!markerDidExist) {
	    const elementMarker = {
		  elementId: newElement.elementId,
		  pathId: newElement.pathId,
		  label: newElement.title,
		  description: newElement.address,
		  family: newElement.subType,
		  value: newElement.elementId,
		  parentId: null,
		  lat: parseFloat(newElement.coordinates[0]),
		  lng: parseFloat(newElement.coordinates[1]),
		  destLat: parseFloat(newElement.coordinates[0]),
		  destLng: parseFloat(newElement.coordinates[1]),
	    };
	    elementsRelations.push(elementMarker);
	  }
	}
  }

  return (
    <Form 
	  defaultValues={element} 
	  onSubmit={(value) => handleSubmit(value)} 
	  className={styles.formContainer}
	>
      {({ control, register, watch, setValue }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  {!tableMode && (
		    <HorizontalGroup key={'title'} align="flex-start" height="35px"> 
		      <Label className={styles.labelStyle} >
			    {typeToString(watch().elementType) + ' ' + watch().title}
			  </Label>
		    </HorizontalGroup>
		  )}
		  <HorizontalGroup key={'status'} align="flex-start" height="55px"> 
		    <Field label="Habilitar" className={styles.switchContainer}>
              <Switch id="state" className={styles.switchStyle} {...register('state')} />
            </Field>
			{watch().state && watch().elementType !== 'splicer' && watch().elementType !== 'splitter' && (
			  <Field 
				label="Estado administrativo:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="status"
					  {...field}
					  className={styles.select}
					  onChange={(value) => onChange(mapSelectValueToString(value))}
					  options={valueToStatus}
					  defaultValue={statusToString(field)}
					  title="Estado administrativo del elemento que esta configurando"
					/>
				  )}
				  control={control}
				  name="status"
				/>
			  </Field>
			)}
		  </HorizontalGroup>
		  {watch().state && (
			<HorizontalGroup key={'type'} align="flex-start" height="55px">
			  <Field 
				label="Tipo:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="elementType"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
					    updateValidSubTypes(value.value);
						onChange(mapSelectValueToString(value));
					  }}
					  options={valueToType}
					  defaultValue={typeToString(field)}
					  title="Tipo de elemento que esta definiendo"
					/>
				  )}
				  control={control}
				  name="elementType"
				/>
			  </Field>
			  {watch().elementType !== 'path' && (
			    <Field 
				  label="Subtipo:"
			      className={styles.nameContainer}
			    >
				  <InputControl
				    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="subType"
					    {...field}
					    className={styles.select}
					    onChange={(value) => {
						  if (watch().elementType === 'segment') {
						    updateSegment(watch().capacity, value.value, watch().linkId);
						    updateLinks(value.value);
						  }
						  onChange(mapSelectValueToString(value));
					    }}
					    options={validSubTypes}
					    defaultValue={subtypeToString(field)}
					    title="Tipo de enlace que esta definiendo"
					  />
				    )}
				    control={control}
				    name="subType"
				  />
			    </Field>
			  )}
		    </HorizontalGroup>
		  )}
		  <HorizontalGroup key={'main'} align="flex-start" height="55px"> 
			<Field 
			  label="Designación:"
			  className={styles.nameContainer}
			>
			  <InputControl
			    render={({ field }) => (
                  <Input
                    aria-label="title"
                    {...field}
				    className={styles.input}
				    type="text"
					placeholder="Ingrese un identificador..."
					title="Nombre o alias asignado al elemento"
                  />
				)}
				control={control}
				name="title"
			/>
			</Field>
            {watch().elementType !== 'splicer' && watch().elementType !== 'splitter' && (
			  <Field 
		        label="Propietario:"
				className={!tableMode ? styles.nameContainer : styles.nameContainer2}
		      >
                <InputControl
                  render={({ field }) => (
                    <Input
                      aria-label="owner"
                      {...field}
				      className={styles.input}
				      type="text"
					  placeholder="ingrese un nombre o razon social."
					  title="Dueño del recurso."
                    />
                  )}
                  control={control}
                  name="owner"
                />
              </Field>
			)}
		  </HorizontalGroup>
		  {watch().state && (
			<HorizontalGroup key={'admin'} align="flex-start" height="55px" > 
			  {(watch().elementType === 'element' || watch().elementType === 'link') && (
				<Field 
				  label="Trayecto:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
					    aria-label="pathId"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  updateElement(watch().elementType, value.value, watch().pathId);
						  onChange(mapSelectValueToString(value))
						}}
						options={pathIDs}
						defaultValue={field}
						title="Trayecto que sigue el enlace que esta definiendo"
					  />
					)}
					control={control}
					name="pathId"
				  />
				</Field>
			  )}
			  {watch().elementType === 'segment' && (
				<Field 
				  label="ID de Enlace:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
					    aria-label="linkId"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						}}
						options={linkIDs}
						defaultValue={field}
						title="Enlace al que pertenece este segmento"
					  />
					)}
					control={control}
					name="linkId"
				  />
				</Field>
			  )}
			  {(watch().elementType === 'splicer' || watch().elementType === 'splitter') && (
				<>
				<Field 
				  label="Segmento:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="segmentId"
						{...field}
						className={styles.select}
						onChange={(value) => onChange(mapSelectValueToString(value))}
						options={linkSegments}
						defaultValue={segmentToString(field)}
						title="Segmento de enlace donde se realiza el empalme"
					  />
					)}
					control={control}
					name="segmentId"
				  />
				</Field>
				<Field 
				  label="Ubicación:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="pathEventId"
						{...field}
						className={styles.select}
						onChange={(value) => onChange(mapSelectValueToString(value))}
						options={pathEvents}
						defaultValue={eventToString(field)}
						title="Ubicación (Elemento) donde se realiza el empalme"
					  />
					)}
					control={control}
					name="pathEventId"
				  />
				</Field>
				</>
			  )}
		      {(watch().elementType === 'path' || watch().elementType === 'link') && (
			    <>
				  <Field 
					label="Origen:"
					className={styles.nameContainer}
				  >
					<InputControl
					  render={({ field: { onChange, ref, ...field } }) => (
						<Select
						  aria-label="origin"
						  {...field}
						  className={styles.select}
						  onChange={(value) => onChange(mapSelectValueToString(value))}
						  options={places}
						  defaultValue={placeToString(field)}
						  title="Emplazamiento origen del enlace"
						/>
					  )}
					  control={control}
					  name="origin"
					/>
				  </Field>
				  <Field 
					label="Destino"
					className={styles.nameContainer}
				  >
					<InputControl
					  render={({ field: { onChange, ref, ...field } }) => (
						<Select
						  aria-label="destination"
						  {...field}
						  className={styles.select}
						  onChange={(value) => onChange(mapSelectValueToString(value))}
						  options={places}
						  defaultValue={placeToString(field)}
						  title="Emplazamiento destino del enlace"
						/>
					  )}
					  control={control}
					  name="destination"
					/>
				  </Field>
				</>
			  )}
			</HorizontalGroup>
		  )}
		  {watch().elementType === 'path' && (
			<HorizontalGroup key={'location'}> 
			  <CollapsibleSection 
			    label="Ubicación"
			    className={styles.collapseContainer}
			    onChange={(isCollapsed) => {
				  updateElement(watch().elementType, watch().pathId, watch().pathId);
				  setIsPathOpen(isCollapsed);
			    }}
			  >
			    {isPathOpen && tableMode && (
				  <div className={styles.mapContainerFull}>
				    <MapComponent 
					  currentNodes={[
					    {
						  uid: null,
						  lat: null,
						  lng: null,
						  label: watch().title,
						  family: watch().subType,
						  pathId: watch().pathId,
					    }
					  ]}
				      onCoordinatesChange={(coords, currentPlace) => {
					    handlePlaceCoordsChange(coords, currentPlace);
				      }}
				      markers={[...parentPlaces, ...elementsRelations]}
				      showMarkerIcons={true}
				      mapSource={pluginVariables[4]}
				      geoVariables={[pluginVariables[5], pluginVariables[6], pluginVariables[7], pluginVariables[8]]}
				      readOnly={false}
					  tableMode={true}
				    />
			      </div>
			    )}
			  </CollapsibleSection>
			</HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'element' && (
			<HorizontalGroup key={'location'}> 
			  <VerticalGroup>
			    <CollapsibleSection 
			      label="Ubicación"
				  className={styles.collapseContainer}
			      onChange={(isCollapsed) => {
			        updateElement(watch().elementType, watch().pathId, watch().pathId);
				    if (coordinates.length > 0) {
				      setIsLocationOpen(isCollapsed);
				    }
			      }}
			    >
				  <HorizontalGroup key={'coordinates'} align="flex-start" height="55px"> 
				    <Field 
				      label="Coordenadas [lat, lon]:"
				      className={styles.nameContainer}
			        >
				      <Input
					    aria-label="coordinates"
					    className={styles.input}
					    value={coordinates.join(', ')}
					    onChange={(e) => {
						  const isReady = handleCoordsChange(e.target.value);
						  setIsLocationOpen(isReady);
						}}
					    type="text"
					    placeholder="lat, lon"
					    title="Coordenadas geograficas en el formato [lat, lon], con latitud y longitud numéricas"
				      />
			        </Field>
			        <Field 
				      label="Elevación:"
				      className={styles.nameContainer4}
			        >
				      <InputControl
				        render={({ field }) => (
					      <Input
					        aria-label="elevation"
					        {...field}
					        className={styles.input}
					        type="number"
					        title="Elevación del sitio en mts"
					        required
					      />
				        )}
				        control={control}
				        name="elevation"
				      />
			        </Field>
				  </HorizontalGroup>
			      <HorizontalGroup>
				    <Field 
				      label="Emplazamiento:"
				      className={styles.textContainer2}
			        >
					  <Field className={styles.nameContainer4}>
						<Select
						  aria-label={`emp`}
						  className={styles.select}
						  options={places}
						  value={coordinatesToPlace(coordinates)}
						  onChange={(v) => {
							const isReady = handleCoordsChange(v.lat + ',' + v.lng);
						    setIsLocationOpen(isReady);}
						  }
						  title="Seleccionar el ID del elemento"
						/>
					  </Field>
			        </Field>
				  </HorizontalGroup>
			      <HorizontalGroup>
				    <Field 
				      label="Dirección:"
				      className={styles.textContainer2}
			        >
				      <InputControl
				        render={({ field }) => (
					      <TextArea
					        aria-label="address"
					        {...field}
					        className={styles.textArea}
							rows="4"
					        type="text"
					        title="Dirección postal del sitio"
					      />
				        )}
				        control={control}
				        name="address"
				      />
			        </Field>
				  </HorizontalGroup>
			    </CollapsibleSection>
				<CollapsibleSection 
				  label="Relaciones del Elemento"
				  className={styles.collapseContainer} 
				  onChange={(isCollapsed) => {
					updateParentMarkers(relations);
					if (relations.length > 0) {
					  setIsParentOpen(isCollapsed);
					}
				  }}
				>
				  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
					<Label className={styles.nameContainer4} >{'ID Elemento'}</Label>
					<Label className={styles.nameContainer4} >{'Distancia'}</Label>
					<Label className={styles.nameContainer4} >{'Capacidad'}</Label>
					<button 
					  type="button" 
					  className={styles.button} 
					  onClick={() => {
						handleAddParent();
					  }}
					  title={'Agregar una nueva relación'}
					>
					  <div className={styles.add_icon}>
						<Icon name="plus-circle-fill" size="lg" />
					  </div>
					</button>
				  </HorizontalGroup>
				  {relations.map((parent, index) => (
					<HorizontalGroup key={'conexion_data'} align="flex-start" height={'35px'} >
					  <Field className={styles.nameContainer4}>
						<Select
						  aria-label={`parent ${index + 1}`}
						  className={styles.select}
						  options={pathEvents}
						  value={parent.element_id}
						  onChange={(v) => handleChange(index, 'element_id', v.value)}
						  title="Seleccionar el ID del elemento"
						/>
					  </Field>
					  <Field className={styles.nameContainer4}>
						<Input
						  aria-label={`distance ${index + 1}`}
						  className={styles.input}
						  type="number"
						  value={parent.distance}
						  onChange={(e) => handleChange(index, 'distance', e.currentTarget.value)}
						  placeholder="Distancia total al elemento"
						  title="Ingresar la distancia"
						/>
					  </Field>
					  <Field className={styles.nameContainer4}>
						<Input
						  aria-label={`capacity ${index + 1}`}
						  className={styles.input}
						  type="number"
						  value={parent.capacity}
						  onChange={(e) => handleChange(index, 'capacity', e.currentTarget.value)}
						  placeholder="Capacidad total"
						  title="Ingresar la capacidad"
						/>
					  </Field>
					  <button 
						type="button" 
						onClick={() => handleRemoveParent(index)}
						className={styles.button}
					  >
						<div className={styles.del_icon}>
						  <Icon name="trash-circle-fill" size="lg" />
						</div>
					  </button>
					</HorizontalGroup>
				  ))}
				</CollapsibleSection>
			  </VerticalGroup>
			  {(isLocationOpen || isParentOpen) && coordinates && coordinates !== null && tableMode && (
			    <VerticalGroup>
			      <div className={(isLocationOpen && isParentOpen) ? styles.mapContainer2 : styles.mapContainer}>
			        <MapComponent 
					  currentNodes={[
					    {
						  uid: element.uid,
						  lat: coordinates[0],
						  lng: coordinates[1],
						  label: watch().title,
						  family: watch().subType,
						  pathId: watch().pathId,
					    }
					  ]}
					  onCoordinatesChange={(coords, currentPlace) => {
					    handlePlaceCoordsChange(coords, currentPlace);
					  }}
					  markers={[...parentPlaces, ...elementsRelations]}
					  showMarkerIcons={isParentOpen}
					  mapSource={pluginVariables[4]}
					  geoVariables={[pluginVariables[5], pluginVariables[6], pluginVariables[7], pluginVariables[8]]}
					  readOnly={false}
					  tableMode={true}
				    />
			      </div>
			    </VerticalGroup>
			  )}
			</HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'segment' && (
			<HorizontalGroup key={'general'} align="flex-start" height="55px" >  
			  <Field 
				label="Origen:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="origin"
					  {...field}
					  className={styles.select}
					  onChange={(value) => onChange(mapSelectValueToString(value))}
					  options={pathEvents}
					  defaultValue={placeToString(field)}
					  title="Emplazamiento origen del enlace"
					/>
				  )}
				  control={control}
				  name="origin"
				/>
			  </Field>
			  <Field 
				label="Destino:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="destination"
					  {...field}
					  className={styles.select}
					  onChange={(value) => onChange(mapSelectValueToString(value))}
					  options={pathEvents}
					  defaultValue={placeToString(field)}
					  title="Emplazamiento destino del enlace"
					/>
				  )}
				  control={control}
				  name="destination"
				/>
			  </Field>
			  {(parentSubType === 'fo_path' || parentSubType === 'ge_path') && (
			    <Field 
				  label="Capacidad:"
				  className={styles.nameContainer4}
			    >
				  <Input
				    aria-label="capacity"
				  	className={styles.input}
					type="number"
				    value={linkCapacity}
					onChange={(e) => {
					  setLinkCapacity(e.currentTarget.value);
					  updateSegment(e.currentTarget.value, watch().subType, watch().linkId);
					}}
					title={parentSubType === 'fo_path' ? 'Cantidad de fibras en el cable' : 'Cantidad de pares en el cable'}
				  />
			    </Field>
			  )}
			  {(parentSubType === 'sa_path' || parentSubType === 're_path') && (
			    <Field 
				  label="Capacidad:"
				  className={styles.nameContainer4}
			    >
				  <InputControl
				    render={({ field }) => (
					  <Input
					    aria-label="capacity"
					    {...field}
					    className={styles.input}
					    onChange={(value) => {
						  updateSegment(1, watch().subType, watch().linkId);
					    }}
					    type="number"
					    title={'Capacidad Total de Transporte en Mbps'}
					  />
				    )}
				    control={control}
				    name="capacity"
				  />
			    </Field>
			  )}
			  <Field 
				label="Caracteristicas:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field }) => (
					<Input
					  aria-label="segmentType"
					  {...field}
					  className={styles.input}
					  type="text"
					  placeholder="Medio fisico"
					  title="Caracteristicas del cable o enlace de radio empleado"
					/>
				  )}
				  control={control}
				  name="segmentType"
				/>
			  </Field>
			</HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType === 'radio' && (parentSubType === 're_path' || parentSubType === 'sa_path') && (
			<CollapsibleSection 
			  label="Detalles del Radio Enlace" 
			  onChange={(isCollapsed) => {
			    console.log('collapse', isCollapsed);
			  }}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Frecuencia (Canal)'}</Label>
				<Label className={styles.nameContainer4} >{'Ancho de Banda'}</Label>
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
				<Label className={styles.nameContainer4} >{'SNR'}</Label>
			    <button 
				  type="button" 
				  className={styles.button} 
				  onClick={() => {
				    handleAddSublink();
				  }}
				  title={'Agregar una nueva relación'}
			    >
				  <div className={styles.add_icon}>
				    <Icon name="plus-circle-fill" size="lg" />
				  </div>
			    </button>
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`channel ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.buffer}
				      onChange={(e) => handleChangeSublink(index, 'buffer', e.currentTarget.value)}
					  title="Canal o Frecuencia de operación en MHz"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`bw ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.color}
				      onChange={(e) => handleChangeSublink(index, 'color', e.currentTarget.value)}
					  title="Ancho de banda del Canal en MHz"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  placeholder="Distancia del segmento"
					  title="Ingresar la distancia total del segmento de enlace en mts"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
					  aria-label={`capacity ${index + 1}`}
					  className={styles.input}
					  type="number"
					  value={sublink.attenuation}
					  onChange={(e) => handleChangeSublink(index, 'attenuation', e.currentTarget.value)}
					  placeholder="SNR"
					  title="Ingresar Relación Señal-Ruido del enlace en dB obtenida en la puesta en servicio"
				    />
				  </Field>
                  <button 
				    type="button" 
					onClick={() => handleRemoveSublink(index)}
					className={styles.button}
				  >
				    <div className={styles.del_icon}>
					  <Icon name="trash-circle-fill" size="lg" />
				    </div>
                  </button>
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType !== 'radio' && parentSubType === 'fo_path' && (
			<CollapsibleSection 
			  label="Detalles del Cable" 
			  onChange={(isCollapsed) => {
			    console.log('collapse', isCollapsed);
			  }}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Buffer'}</Label>
				<Label className={styles.nameContainer4} >{'Color'}</Label>
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
				<Label className={styles.nameContainer4} >{'Atenuación'}</Label>
			    <button 
				  type="button" 
				  className={styles.button} 
				  onClick={() => {
				    handleAddSublink();
				  }}
				  title={'Agregar una nueva relación'}
			    >
				  <div className={styles.add_icon}>
				    <Icon name="plus-circle-fill" size="lg" />
				  </div>
			    </button>
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Select
					  aria-label={`buffer ${index + 1}`}
					  className={styles.select}
					  options={bufferColors}
					  value={sublink.buffer}
					  onChange={(v) => handleChangeSublink(index, 'buffer', v.value)}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Select
					  aria-label={`color ${index + 1}`}
					  className={styles.select}
					  options={fiberOpticColors}
					  value={sublink.color}
					  onChange={(v) => handleChangeSublink(index, 'color', v.value)}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  placeholder="Distancia del segmento"
					  title="Ingresar la distancia total del segmento de enlace en mts"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
					  aria-label={`capacity ${index + 1}`}
					  className={styles.input}
					  type="number"
					  value={sublink.attenuation}
					  onChange={(e) => handleChangeSublink(index, 'attenuation', e.currentTarget.value)}
					  placeholder="Atenuación del segmento"
					  title="Ingresar la atenuación total del segmento de enlace en dB"
				    />
				  </Field>
                  <button 
				    type="button" 
					onClick={() => handleRemoveSublink(index)}
					className={styles.button}
				  >
				    <div className={styles.del_icon}>
					  <Icon name="trash-circle-fill" size="lg" />
				    </div>
                  </button>
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType !== 'radio' && parentSubType === 'ge_path' && (
			<CollapsibleSection 
			  label="Detalles del Cable" 
			  onChange={(isCollapsed) => {
			    console.log('collapse', isCollapsed);
			  }}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Grupo o Conjunto'}</Label>
				<Label className={styles.nameContainer4} >{'Color'}</Label>
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
				<Label className={styles.nameContainer4} >{'Atenuación'}</Label>
			    <button 
				  type="button" 
				  className={styles.button} 
				  onClick={() => {
				    handleAddSublink();
				  }}
				  title={'Agregar una nueva relación'}
			    >
				  <div className={styles.add_icon}>
				    <Icon name="plus-circle-fill" size="lg" />
				  </div>
			    </button>
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`buffer ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.buffer}
				      onChange={(e) => handleChangeSublink(index, 'buffer', e.currentTarget.value)}
					  placeholder="Grupo"
					  title="Ingrese el número de grupo o conjunto de pares"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Select
					  aria-label={`color ${index + 1}`}
					  className={styles.select}
					  options={multiparColors}
					  value={sublink.color}
					  onChange={(v) => handleChangeSublink(index, 'color', v.value)}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  placeholder="Distancia del segmento"
					  title="Ingresar la distancia total del segmento de enlace en mts"
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
					  aria-label={`capacity ${index + 1}`}
					  className={styles.input}
					  type="number"
					  value={sublink.attenuation}
					  onChange={(e) => handleChangeSublink(index, 'attenuation', e.currentTarget.value)}
					  placeholder="Atenuación del segmento"
					  title="Ingresar la atenuación total del segmento de enlace en dB"
				    />
				  </Field>
                  <button 
				    type="button" 
					onClick={() => handleRemoveSublink(index)}
					className={styles.button}
				  >
				    <div className={styles.del_icon}>
					  <Icon name="trash-circle-fill" size="lg" />
				    </div>
                  </button>
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && (
			<CollapsibleSection 
			  label="Instalación y Mantenimiento" 
			  onChange={(isCollapsed) => {
			    console.log('collapse', isCollapsed);
			  }}
			>
			  <HorizontalGroup key={'manteinance'} align="flex-start" height="55px" >  
			    <Field 
				  label="Fecha de instalación"
				  className={styles.nameContainer}
			    >
				  <InputControl
				    render={({ field }) => (
					  <Input
					    aria-label="installationDate"
					    {...field}
					    className={styles.input}
					    type="date"
					    placeholder="ingrese una fecha"
					    title="Fecha de instalación."
					  />
				    )}
				    control={control}
				    name="installationDate"
				  />
			    </Field>
			    <Field 
				  label="Ultima inspección"
				  className={styles.nameContainer}
			    >
				  <InputControl
				    render={({ field }) => (
					  <Input
					    aria-label="lastInspection"
					    {...field}
					    className={styles.input}
					    type="date"
					    placeholder="ingrese una fecha"
					    title="Fecha de la última inspección realizada al enlace"
					  />
				    )}
				    control={control}
				    name="lastInspection"
				  />
			    </Field>
			    <Field 
				  label="Próximo mantenimiento"
				  className={styles.nameContainer}
			    >
				  <InputControl
				    render={({ field }) => (
					  <Input
					    aria-label="nextMaintenance"
					    {...field}
					    className={styles.input}
					    type="date"
					    placeholder="ingrese una fecha"
					    title="Fecha programada para el siguiente mantenimiento"
					  />
				    )}
				    control={control}
				    name="nextMaintenance"
				  />
			    </Field>
			  </HorizontalGroup>
			  <Field 
		        label="Notas:"
			    className={styles.textContainer}
			  >
			    <InputControl
				  render={({ field }) => (
				    <TextArea
					  aria-label="installationNotes"
					  {...field}
					  className={styles.textArea}
					  rows="2"
					  placeholder="Notas de la instalación"
					  title="Notas relacionadas con la instalación o sus modificaciones"
				    />
				  )}
				  control={control}
				  name="installationNotes"
			    />
			  </Field>
			</CollapsibleSection>
		  )}
		  <div className={styles.buttonGroup}>
		    <button 
			  type="submit"
			  className={styles.okButton} 
			  title={'Guardar modificaciones'}
			>
			  <div className={styles.del_icon}>
			    <Icon name={'save'} size="lg" />
			  </div>
			  {'Guardar'}
			</button>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={() => {
			    if (!tableMode) {
			      if (pluginVariables[9] && pluginVariables[9] !== '') {
				    const queryMap = {
					  [`var-${pluginVariables[9]}`]: '',
				    };
				    try {
					  locationService.partial(queryMap, true);
				    } catch (error) {
					  console.error(error);
				    }
				  }
				}
			    onCancel();
			  }}
			  title={'Cerrar sin modificar'}
			>
			  <div className={styles.del_icon}>
			    <Icon name="times-fill" size="lg" />
			  </div>
			  {'Cerrar'}
			</button>
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const testButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    mapContainer: css`
	  width: ${width * 0.45}px;
	  min-width: 250px;
	  height: 250px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    mapContainer2: css`
	  width: ${width * 0.45}px;
	  min-width: 250px;
	  height: 350px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    mapContainerFull: css`
	  width: ${width * 0.95}px;
	  min-width: 250px;
	  height: 350px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
	formContainer: css`
      max-width: 100%;
	  border-top: 1px solid ${buttonBorder};
      margin-top: 5px;
      padding-top: 10px;
      margin-left: -10px;
    `,
	collapseContainer: css`
      width: ${width * 0.45}px;
	  min-width: 50px;
	  margin-right: 15px;
	  font-size: 11px;
	  color: ${textColor};
	  box-shadow: none;
      margin-bottom: 15px;
    `,
    nameContainer: css`
      width: ${width * 0.2}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    nameContainer2: css`
      width: ${width * 0.4}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    nameContainer3: css`
      width: ${width * 0.9}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    nameContainer4: css`
      width: ${width * 0.1}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    textContainer: css`
      width: ${width}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    textContainer2: css`
      width: ${width * 0.45}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    switchContainer: css`
      width: ${width * 0.2}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    addButton: css`
      margin: 0px;
	  width: 100%;
	  align-items:right;
      & > * + * {
        margin-right: 8px;
      }
    `,
    labelStyle: css`
      width: ${width * 0.4}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  font-size: 16px;
    `,
    switchStyle: css`
	  width: 32px;
      height: 16px;
      position: relative;
	  top: 4px;
    `,
    select: css`
      flex: 1;
		: 90%;
	  max-width: 350px;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 14px;
    `,
    textArea: css`
      flex: 1;
      width: 90%;
	  margin-top: 2px;
	  font-size: 14px;
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button: css`
      background-color: transparent;
      border: none;
      color: ${textColor};
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 4px 0px 4px;
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    del_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
