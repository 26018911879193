import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { L1OyMData } from './L1OyMManager';
import { Text } from './Tags';
import { valueToType, valueToSubtype, valueToStatus } from './types';
import { DynamicTable, DynamicMap, DynamicTableColumnProps, DynamicTableItemProps } from './L1OyMDynamicTable';

export interface l1OyMTableProps {
  id: string;
  isAdmin: boolean;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

type RouteTableColumnProps = DynamicTableColumnProps<L1OyMData>;
type RouteTableItemProps = DynamicTableItemProps<L1OyMData>;

export const getFilteredElements = (
  elements: L1OyMData[],
  title?: string,
  elementType?: string,
  subType?: string,
  place?: string
) => {
  console.log(elements);
  let elementsFiltered:[] = [];
  elements.forEach((element, index) => {
    let mustFilter = false;
	if (title) {
	  mustFilter = element.title.toLowerCase().includes(title.toLowerCase()) ? mustFilter : true;
    }
	if (elementType) {
	  if (elementType === 'path' && subType) {
	    mustFilter = element.pathId === subType ? mustFilter : true;
	  } else if (elementType === 'link' && subType) {
	    mustFilter = element.linkId === subType ? mustFilter : true;
	  } else if (subType) {
	    mustFilter = element.elementType === elementType && element.subType === subType ? mustFilter : true;
	  }
	} else if (subType) {
	  mustFilter = element.subType.toLowerCase() === subType.toLowerCase() ? mustFilter : true;
    } 
	if (place !== undefined && element.origin !== place && element.destination !== place) {
	  mustFilter = true;
	}
	if (!mustFilter) {
	  elementsFiltered.push(element);
	}
  });
  const sortedElements = elementsFiltered.sort((a, b) => {
    if (a.elementType < b.elementType) return 1;
    if (a.elementType > b.elementType) return -1;

    if (a.subType < b.subType) return 1;
    if (a.subType > b.subType) return -1;

    if (a.title < b.title) return 1;
    if (a.title > b.title) return -1;
  })
  return sortedElements;
};

export const cleanElements = (items: L1OyMData[]): L1OyMData[] => {
  const cleannerElements: L1OyMData[] = [];
  for (let i = 0; i < items.length; i++) {
	const item = items[i].data;
	let elementData = item;
	cleannerElements.push(elementData);
  }
  return cleannerElements;
};

export const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 8; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
}

export const updateElement = (actualElements: L1OyMData[], item: l1OyM): L1OyMData[] => {
  const newElements = [...actualElements];
  const editIndex = newElements.findIndex((element) => element.id === item.id);
  const userName = contextSrv.user.name;
  item.updated = '';
  item.version = item.version + 1;
  item.user = userName;
  if (editIndex >= 0) {
    newElements[editIndex] = {
      ...newElements[editIndex],
      ...item,
    };
  }
  return newElements;
};

export const updateImportElements = (actualElements: L1OyMData[], newElements: L1OyMData[]): L1OyMData[] => {
  const existingElements: L1OyMData[] = [...actualElements];
  for (let i = 0; i < newElements.length; i++) {
	let item = newElements[i];
	const editIndex = existingElements.findIndex((element) => 
	  element.elementType === item.elementType && 
	  element.subType === item.subType && 
	  element.title === item.title
	);
    if (editIndex >= 0) {
      if (item.subType === 'emp') {
	    if (item.coordinates[0] === existingElements[editIndex].coordinates[0] &&
		  item.coordinates[1] === existingElements[editIndex].coordinates[1]) {
	      console.log('Item ya existe');
	    } else if (item.elementType === 'emp') {
          item.updated = '';
	      item.wasChange = true;
          item.version = item.version + 1;
          existingElements[editIndex] = {
            ...existingElements[editIndex],
            ...item,
          };
	    }
	  }
	} else {
	  item.updated = '';
	  item.wasChange = true;
      item.version = item.version + 1;
      existingElements.push(item);
	}
  }
  return existingElements;
};

export const saveImportData = async (newElements: [], nextId: number): L1OyMData[] => {
  const newElementsConfigured: L1OyMData[] = [];
  const userName = contextSrv.user.name;
  const d = Date.now();
  const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
  if (newElements) {
    for (let i = 0; i < newElements.length; i++) {
	  const uidx = generarID();
	  let newElement = {
		id: i + nextId,
		idx: 'EMP_' + uidx.toUpperCase(),
		uid: uidx,
		title: newElements[i].properties.name,
		elementId: newElements[i].properties.styleHash,
		pathId: '',
		linkId: '',
		status: 'in_use',
		state: true,
		installationDate: '',
		installationNotes: '',
		technician: '',
		lastInspection: '',
		nextMaintenance: '',
		owner: '',
		elementType: '',
		subType: '',
		technicalDetails: '',
		origin: '',
		destination: '',
		coordinates: [],
		address: '',
		elevation: 0,
		parentsIds: [],
		relationIds: [],
		relationDistances: [],
		relationCapacities: [],
		sublinkIds: [],
	    sublinkBuffer: [],
	    sublinkColor: [],
		sublinkAttenuation: [],
		sublinkDistance: [],
		segmentId: '',
		pathEventId: '',
		capacity: 0,
		fiberIds: [],
		fiberAttenuation: [],
		lastInspector: '',
		inspectionReport: '',
		inspectionNotes: '',
		inspectionMediaData: '',
		user: userName,
		created: date,
		updated: date,
		version: 0,
		wasChange: false,
	  };
	  if (newElements[i].geometry.type === 'Point') {
		newElement.elementType = 'emp';
		newElement.subType = 'emp';
		newElement.coordinates = [newElements[i].geometry.coordinates[0], newElements[i].geometry.coordinates[1]];
		newElement.elevation = newElements[i].geometry.coordinates[2];
	  } else if (newElements[i].geometry.type === 'LineString') {
		newElement.elementType = 'path';
		newElement.subType = 'path';
		newElement.coordinates = newElements[i].geometry.coordinates;
	  }
	  newElementsConfigured.push(newElement);
	}

    /*const json_values = '{"json_values": {' + 
      '"title":"' + name + '",' + 
	  '"uid":"' + uidx + '",' + 
	  '"user":"' + userName + '",' + 
	  '"version":1,' + 
	  '"groupname":"' + elementType + '",' +
	  '"reference": {' + '"styleHash": "' + styleHash + '"} }';
	var json_data = ', "json_data":' +
	  '{"id": "' + uidx + '",' +
	  '"type": "' + elementType + '",';
	if (elementType === 'path') {
	  json_data = json_data + '"technical_details": { ' +
		'"status": "in_use",' +
		'"geometry": {' + '"coordinates": [' + coords + '] } } } }';
    } else if (elementType === 'emp') {
	  json_data = json_data + '"technical_details": { ' +
		'"status": "in_use",' +
		'"geometry": {' + '"coordinates": [' + String(coords[0]) + ',' + String(coords[1]) + '],' +
		'"elevation": ' + String(coords[2]) + '} } } }';
	}
	const elementToUpdate = json_values + json_data;*/
	return newElementsConfigured;
  }
  return [];
};

export const saveElement = async (elements: L1OyMData[], pluginApi: string): Promise<boolean> => {
  const element = elements.find(element => element.updated === '');

  if (element) {
    const json_values = '{"json_values": {' + 
      '"title":"' + element.title + '",' + 
	  '"uid":"' + element.uid + '",' + 
	  '"user":"' + element.user + '",' + 
	  '"version":' + element.version + ',' + 
	  '"groupname":"' + element.elementType + '",' +
	  '"reference": {' + '"id": "' + element.uid + '"} }';
    var objectId = element.pathId;
	if (element.elementType === 'link') {
	  objectId = element.linkId;
	} else if (element.elementType === 'segment') {
	  objectId = element.segmentId;
	} else {
	  objectId = element.elementId;
	}
	if ((objectId === undefined || objectId === 'undefined' || objectId === '') && element.uid !== undefined) {
	  objectId = element.uid;
	}

	var json_data = ', "json_data":' +
	  '{"id": "' + objectId + '",' +
	  '"type": "' + element.subType + '",' +
	  '"inspection_data": { "last_inspector": "' + element.lastInspector + '",' +
		'"report": "' + element.inspectionReport + '",' +
		'"notes": "' + element.inspectionNotes + '" },' +
	    '"media": { "images": "' + element.inspectionMediaData + '" },';

	if (element.elementType === 'path') {
	  json_data = json_data + '"technical_details": { ' +
		'"status": "' + element.status + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"last_inspection": "' + element.lastInspection + '",' +
		'"next_maintenance": "' + element.nextMaintenance + '",' +
		'"owner": "' + element.owner + '",' +
		'"origin": "' + element.origin + '",' +
		'"destination": "' + element.destination + '",' +
		'"geometry": {' + '"coordinates": [' + element.coordinates + '] },' +
		'"notes": "' + element.installationNotes + '"} } }';
    } else if (element.elementType === 'link') {
	  json_data = json_data + '"technical_details": { ' +
		'"path_id": "' + element.pathId + '",' +
		'"status": "' + element.status + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"last_inspection": "' + element.lastInspection + '",' +
		'"next_maintenance": "' + element.nextMaintenance + '",' +
		'"owner": "' + element.owner + '",' +
		'"parents": ["' + element.pathId + '"],' +
		'"origin": "' + element.origin + '",' +
		'"destination": "' + element.destination + '",' +
		'"notes": "' + element.installationNotes + '"} } }';
    } else if (element.elementType === 'element') {
	  const elevation = element.elevation ? element.elevation : 0;
	  const jsonString = element.relationIds.map((id, index) => ({
		element_id: id,
		distances: element.relationDistances[index],
		capacities: element.relationCapacities[index],
	  }));
	  const sublinkText = JSON.stringify(jsonString, null, 2);
	  json_data = json_data + '"technical_details": { ' +
		'"path_id": "' + element.pathId + '",' +
		'"status": "' + element.status + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"last_inspection": "' + element.lastInspection + '",' +
		'"next_maintenance": "' + element.nextMaintenance + '",' +
		'"owner": "' + element.owner + '",' +
		'"parents": ["' + element.pathId + '"],' +
		'"location": {' + '"coordinates": [' + element.coordinates + '],' +
		  '"address": "' + element.address + '",' +
		  '"elevation": ' + elevation +	'},' +
		'"relations": ' + sublinkText + ',' +
		'"notes": "' + element.installationNotes + '"} } }';
	} else if (element.elementType === 'segment') {
	  const jsonString = element.sublinkIds.map((id, index) => ({
		sublink_id: id,
		buffer: element.sublinkBuffer[index],
		color: element.sublinkColor[index],
		attenuation: element.sublinkAttenuation[index],
		distance: element.sublinkDistance[index],
	  }));
	  const sublinkText = JSON.stringify(jsonString, null, 2);
	  json_data = json_data + '"technical_details": { ' +
		'"link_id": "' + element.linkId + '",' +
		'"status": "' + element.status + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"last_inspection": "' + element.lastInspection + '",' +
		'"next_maintenance": "' + element.nextMaintenance + '",' +
		'"owner": "' + element.owner + '",' +
		'"parents": ["' + element.linkId + '"],' +
		'"segment_type": "' + element.segmentType + '",' +
		'"capacity": ' + element.capacity + ',' +
		'"sublinks": ' + sublinkText + ',' +
		'"origin": "' + element.origin + '",' +
		'"destination": "' + element.destination + '",' +
		'"notes": "' + element.installationNotes + '"} } }';
	} else if (element.elementType === 'splicer') {
	  const jsonString = element.fiberIds.map((id, index) => ({
		fiber_id: id,
		attenuation: element.eventAttenuation[index],
	  }));
	  const sublinkText = JSON.stringify(jsonString, null, 2);
	  const capacity = element.capacity ? element.capacity : 0;
	  json_data = json_data + '"technical_details": { ' +
		'"segment_id": "' + element.segmentId + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"path_event_id": "' + element.pathEventId + '",' +
		'"parents": ["' + element.segmentId + '"],' +
		'"capacity": ' + capacity + ',' +
		'"splicings": ' + sublinkText + ',' +
		'"technician": "' + element.technician + '",' +
		'"notes": "' + element.installationNotes + '"} } }';
	} else if (element.elementType === 'splicer') {
	  const jsonString = element.fiberIds.map((id, index) => ({
		fiber_id: id,
		attenuation: element.eventAttenuation[index],
	  }));
	  const sublinkText = JSON.stringify(jsonString, null, 2);
	  const capacity = element.capacity ? element.capacity : 0;
	  json_data = json_data + '"technical_details": { ' +
		'"segment_id": "' + element.segmentId + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"path_event_id": "' + element.pathEventId + '",' +
		'"parents": ["' + element.segmentId + '"],' +
		'"capacity": ' + capacity + ',' +
		'"splittings": ' + sublinkText + ',' +
		'"technician": "' + element.technician + '",' +
		'"notes": "' + element.installationNotes + '"} } }';
	}

	const elementToUpdate = json_values + json_data;
	axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	try {
	  const response = await axios.post(pluginApi, elementToUpdate);
	  if (response.statusText === 'OK') {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, ['Elemento actualizado correctamente']);
        return true;
      } else {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
        return false;
      }
	} catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Error al actualizar elemento: ' + error.response.status]);
      return false;
    }
	return false;
  }
};

export const L1OyMTable: FC<l1OyMTableProps> = ({
  id,
  isAdmin,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  places,
  types,
  pluginVariables,
  isKmzAddMode
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const actualElements: [] = [];
  elements.forEach((element, index) => {
	actualElements.push(element);
  });

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const statusToSubtype = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const subtypeToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.state);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'subType',
      label: 'Tipo',
      renderCell: (item) => {
		return item.data.subType.length ? (
          <Text text={subtypeToString(item.data.subType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.status);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
    {
      id: 'version',
      label: 'Versión',
      renderCell: (item) => {
		return item.data.version ? (
          <Text text={String(item.data.version)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 5,
	});
	cols.push({
	  id: 'updated',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.updated ? (
		  <Text text={item.data.updated} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 6,
	});
  }

  const elementsFiltered = useMemo(
    () => getFilteredElements(actualElements, filters?.title, filters?.elementType, filters?.subType, filters?.place),
    [actualElements, filters]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (actualElements.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        items={dynamicTableElements}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		places={places}
		types={types}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};

export interface L1OyMMapProps {
  id: string;
  isAdmin: boolean;
  onImportData: (newElements: []) => void;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const L1OyMMap: FC<L1OyMMapProps> = ({
  id,
  isAdmin,
  onImportData,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  places,
  types,
  pluginVariables,
  isKmzAddMode
}) => {
  const actualElements: [] = [];
  elements.forEach((element, index) => {
	actualElements.push(element);
  });

  const elementsFiltered = useMemo(
    () => getFilteredElements(actualElements, filters?.title, filters?.elementType, filters?.subType, filters?.place),
    [actualElements, filters]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (actualElements.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicMap
        items={dynamicTableElements}
		onImportData={onImportData}
		onSave={onSave}
		onCancel={onCancel}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		places={places}
		types={types}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};